import React, { Component } from 'react';
import { Typography, Divider, Grid, CardContent, Container } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import { GetData } from '../api/service';
import BreadCrumbOwn from '../component/Essentials/BreadCrumbOwn';


class DepertmentContainer extends Component {
    state = {
        departments: [],
       
    }

    componentDidMount() {
        this.setState({
            departments: this.props.departments,
          
        })
    }


 

    render() {
        return (
            <div>
              
              
              <Container className='mb-5'>
                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                  Departments
                </Typography>
              
                <Typography variant="body1" gutterBottom>
                    Choose the department you want to view in details
                </Typography>

                <Divider />
                <br />

                <Grid container spacing={2}>
                    {this.state.departments.map((el, index) => <Grid key={index} item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.history.replace(`/department/${el.dept_code}`)}
                    >
                        <Card border="primary">
                            <CardContent>
                              <div style={{height:'70px'}}>
                                <Typography
                                    variant="subtitle2"
                                    style={{fontWeight: 500, fontSize: 15, color: "#2054CD"}}
                                >
                                    Department of {el.dept_name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{fontWeight: 700, fontSize: 14, color: "grey"}}
                                >
                                    {el.dept_name_as}
                                </Typography>
                              </div>
                            </CardContent>
                            
                            {/* {Array.isArray(el.banner) && el.banner.length > 0 && <img style={{width:'100%',height:'190px', }} src={el.banner[0]} alt={el.banner[0]} />} */}
                        </Card>
                    </Grid>)}
                </Grid>
              </Container>
            </div>
        );
    }
}



export default DepertmentContainer;