import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { Container, Card, CardActions, CardContent, Grid, List, ListItem, Typography } from "@material-ui/core";
import { PostData } from "../api/service";

import ShowImage from "../component/Department/ShowImage";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Menu, Button } from "antd";
import { Link } from "react-router-dom";

const list = [
  {
    year: 2024,
  },
  {
    year: 2023,
  },
  {
    year: 2022,
  },
  {
    year: 2021,
  },
  {
    year: 2020,
  },
  {
    year: 2019,
  },
]

export default class EventsShort extends Component {

  state = {
    data: [],
    isLoaded: false,
    year: '',
  }

  __loadFile = (year) => {

    this.setState({
      isLoaded: false
    })

    let d = {
      dept_code: 'PDUAM',
      type: this.props.type,
      year: year
    }

    PostData(`/getcollegeeventlistwithyear`, d)
      .then((resp) => {
        this.setState({
          data: resp,
          year: year,
          isLoaded: true
        })
      })
  }

  componentDidMount() {
    this.__loadFile();
  }

  setYear = (year) => {
    this.__loadFile(year)
  }

  render() {
    return (
      <div >
     
        <div className="my-4" style={{ minHeight: '500px' }}>
          <Container>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: "1em" }}
              >
                <Card
                  style={{
                    boxShadow: "none",
                    background: "",
                    borderRadius: "none",
                  }}
                >
                  <Typography variant="h6" style={{ margin: "1em 0" }} gutterBottom>
                    Event Archives
                  </Typography>
                  <Menu mode="horizontal">
                    {list.map((el, index) => (
                      <Menu.Item
                        button
                        // style={{
                        //     border: "1px solid #0002",
                        //     marginBottom: "2px",
                        //     backgroundColor: el === this.state.com ? '#F5EB3D' : '#f9f9f9'
                        // }}
                        key={index}
                        onClick={() => this.setYear(el.year)}
                        style={{
                          //backgroundColor: el.link === `/iqac/${this.state.link}` ? '#F5EB3D' :''
                        }}
                      >

                        {el.year}

                      </Menu.Item>
                    ))}
                  </Menu>

                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>



                <br />
                {this.state.isLoaded ? (
                  <Grid container spacing={2}>
                    {this.state.data.map((el, index) =>
                      <Grid item key={index} xs={12} sm={12} lg={6} md={6} style={{backgroundColor: "#f9f9f9", marginBottom: 10}}>
                        <Card>
                          <div style={{ flexBasis: "40%" }}>
                            <ShowImage
                              files={el.featured_photo}
                              nos="1"
                              height="300px"
                              width="100%"
                            />
                          </div>
                          <CardContent style={{ height: 80 }}>
                            <Typography
                              variant="h6"
                              style={{ fontSize: 14, fontWeight: 700, color: "#2C3E50" }}
                              gutterBottom
                            >
                              {el.title}
                            </Typography>
                            
                           
                          </CardContent>
                          <CardActions>
                          <Link to={`/singleevent?eventId=${el.id}`}>
                              <Button type="primary">View Event</Button>
                            </Link>
                          </CardActions>



                        </Card>
                      </Grid>

                    )}

                    

                    {
                      this.state.data.length === 0 || this.state.data.length == 'undefined' ? (
                        <Typography>No Events found</Typography>
                      ) : null
                    }

                  </Grid>
                ) : <Typography>Loading . . . </Typography>}

              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
