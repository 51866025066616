import { Card } from '@material-ui/core'
import React, { Component } from 'react'

export default class CodeOfConductContainer extends Component {
  render() {
    return (
      <div >
        <Card style={{padding:'20vh',textAlign:'center'}}>
            <h3>Page under Construction</h3>
        </Card>
      </div>
    )
  }
}
