import React, { Component } from "react";
import { Card, Container } from "@material-ui/core";
import NotificationDescription from "../Notifications/NotificationDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

export default class OrganogramContainer extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Organogram" />

        <Container className="mb-5">
          <NotificationDescription
            type="streategies"
            code="IQAC_ORGANOGRAM"
            title="Organogram"
          />
        </Container>
      </div>
    );
  }
}
