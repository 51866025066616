import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { PostData } from "../api/service";

export default class RulesAndRegulationsContainer extends Component {
    state = {
        data: "",
        isLoaded: false,
      };
    
      componentDidMount() {
        let d = {
          dept_code: "PDUAM",
          type: "RULES_AND_REGULATIONS",
        };
    
        PostData(`/getnoticesbytypedept`, d).then((resp) => {
          if (resp.length > 0) {
            this.setState({
              data: resp[0],
              isLoaded: true,
            });
          }
        });
      }
    render() {
        return (
            <div>

                <BreadCrumbOwn title="Rules and Regulations" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    {this.state.isLoaded && this.state.data && <div className="prose" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>}
                    {/* <div>
                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                            Attendance Rules
                        </Typography>
                        <Typography variant="body2">Attendance in classes is strictly compulsory. A student must not attend less than 75% of the lectures delivered in each subject to become eligible for appearing in the Council/ University Examination. A student not fulfilling the above requirement will not be allowed to appear in the final examination.</Typography>

                        <br />

                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                            College Uniform
                        </Typography>
                        <Typography variant="body2">Wearing of the college uniform is compulsory for all students for all purposes during their stay inside the college campus.
                        </Typography>

                        <br />

                        <Typography variant="title" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                            H.S. and U.G. Programs

                        </Typography>
                        <Typography variant="body2"><strong>For Boys</strong>: Satin blue shirt decorated with the college emblem and blackish blue trousers, black shoes, white socks and blue blazer/sweater (V-shaped white stripe). Jeans trousers and shirts are not permitted. The design and style of the uniform must be formal.

                            <br />

                            <strong>For Girls</strong>: Satin blue kurta decorated with the college emblem and blackish blue churidar, and navy blue dupatta and navy blue sweater decorated with the college emblem. Jeans and leggings are not permitted. The design and style of the uniform must be formal.
                        </Typography>

                        <br />


                        <Typography variant="title" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                            P.G. Programs


                        </Typography>
                        <Typography variant="body2"><strong>For Boys</strong>: Formal shirt of blackish blue thin stripes on off-white colour decorated with the college emblem and blackish blue trousers, black shoes, white socks and navy blue blazer/sweater (V-shaped white stripe) decorated with the college emblem. The design and style of the uniform must be formal.

                            <br />

                            <strong>For Girls</strong>: Formal kurta of blackish blue thin stripes on off-white colour decorated with the college emblem and blackish blue churidar, and navy blue dupatta and navy blue sweater decorated with the college emblem. The design and style of the uniform must be formal.

                            <br />

                            T-shirts and jeans trousers (designed by any department) are not allowed in the classroom and on the college premises.
                            For further details, students may contact the following: UNIQUE BRAND, Phone No: 95084-15363; 97070-98210
                        </Typography>

                        <br />


                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Maintenance of Discipline

                        </Typography>
                        <Typography variant="body2">The students must observe complete silence on the College premises. They must not loiter aimlessly within the College campus. Students must spend their spare time/off periods in such a way that will not cause any interference with the work of others. Bike-holders must obtain gate passes from the Principal. Cell phones are strictly prohibited in the College campus. Students found using cell phones are liable to face disciplinary action including the seizure of the cell phones by the Discipline Maintenance Committee members. Students should, in their own interest, read everyday all the notices displayed on the notice boards from time to time, and be fully aware of the information displayed thereon.

                        </Typography>

                        <br />

                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Student’s Identity Card


                        </Typography>
                        <Typography variant="body2">Every student is issued an identity card at the time of admission. The card which is non-transferable contains information about the holder, such as Name, Session, Class, Roll No., Combination of subjects, etc. It also contains a passport size photograph of the student duly endorsed by the Principal. It is mandatory for every student to carry the identity card with him/her so that it can be produced whenever necessary.


                        </Typography>

                        <br />

                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Internal Examination



                        </Typography>
                        <Typography variant="body2">Students should attend at least two unit tests/sessional examinations, held for each subject of each class in all the streams during the academic calendar. Allowing a candidate for the Final Examination will be determined by the overall performance of the student in the unit tests/ sessional examinations, class attendance, etc. Internal Assessment will be done taking into account the students’ attendance and performance in the unit tests/sessional examinations.



                        </Typography>

                        <br />

                        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Punishable Acts of Indiscipline



                        </Typography>
                        <Typography variant="body2">

                            <ol>
                                <li>An act which causes destruction or defacing of the property of the College or organizing raids and breaking into the premises of the college.
</li>
<li>An act of insubordination or rude behavior and use of impertinent language towards the Principal or any faculty member of the College or non-teaching staff.
</li>
<li>An act which disrupts the smooth functioning of the College or environment conducive to the pursuit of knowledge or harmonious relationship between different groups of students.
</li>
<li>Any student found to be involved in ragging is liable to be expelled from the college and in addition may undergo imprisonment and fine, as per sections laid down in the Indian Penal Code.
</li>
<li>An act of unruly and disorderly behaviour at any competitions, sports, cultural activities or other social or educational programmes organized by the College.
</li>
<li>Smoking, consumption of alcoholic drinks, intoxicants and narcotic drugs, eve-teasing
</li>
<li>Collection of funds for any programme, picnic, excursion, project or activity without prior written permission of the appropriate authority.
</li>
<li>Use of the name or address of the College or its officials or other authorities for making any representation on behalf of any student or a group of students to any public authority in India or elsewhere or to any person (including the press) other than a person responsible for the management of the affairs of the College.
</li>
<li>Generally, any other act of indiscipline which, in the opinion of the Principal of the College is unbecoming of a student.
</li>
<li>An act which interferes with the personal liberty of another person or subjects another to indignity, or involves physical violence or use of abusive language.
</li>
                            </ol>



                        </Typography>


                    </div> */}
                </Container>
            </div>
        );
    }
}
