import React, { Component } from 'react'

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import { Card, CardHeader } from 'reactstrap';
import { Typography, Container } from '@material-ui/core';
import BreadCumb from '../Department/BreadCumb';
import BreadCrumbOwn from '../Essentials/BreadCrumbOwn';



const facultyData = [
    {
        name: 'Dr. P.K. Dutta',
        desg: 'Principal',
        qualification: 'B.E., AEC, Ghy',
        email: 'pranjalkr.saikia@gmail.com',
        phone: '8876371354',
        specifications: 'Programming',
        cv: '',
        photo: ''
    },

]


export default class GeneralRule extends Component {


    render() {
        return (
            <div>
                <BreadCrumbOwn title="Management Rule" />
                <Container >

                    <br />

                    <div>
  

                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            1.	It is obligatory to attend classes regularly. A student is required to obtain a minimum of 80% attendance of the total lectures delivered on each academic session failing of which he or she will be debarred from appearing in the examination. He or she may also have to forfeit the seat. 
                    </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            2.	All students are required to attend in college uniform.  
                    </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            3. 	All Societies & Associations of the college shall be subject to guidance & control of the college authority.  
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            4.	Only societies recognised by the authority shall have access to the facilities of the college campus.  
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            5.	All notices desired to be circulated by the Students Union in the college premises shall require the prior approval of the Principal.  
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            6.	Any of the following acts by a student will be considered as punishable offence -<br/>
	(a)  Habitual irregularity.<br/>
	(b)  Indulgence in any kind of violent activities .<br/>
	(c)  Disobedience & indecent behaviour towards teachers, non-teaching staff, support staff, visitors &  the  college authority.<br/>
	(d)  Damaging, defacing or devaluating any college property.
  
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            7.	Use of mobile phones within the campus is restricted at canteen, common rooms and at departmental rooms with due permission.  
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            <Typography
                            variant="body1"
                            gutterBottom
                        >
                            8.	The campus of the college has already been declared  a 'Tobacco Free Zone'. So use of tobacco in the college campus is punishable as per law  
                    </Typography> 
                    </Typography>

                    <Typography
                            variant="body1"
                            gutterBottom
                        >
                            9. No Student shall take recourse to any social media for expressing his/her grievances. The Students Grievances Redressal Cell should be approched first for such grievances. Violation of this rule will invite stern disciplinary measures.

  
                    </Typography>
                    <h4>

                    </h4>

                    </div>






                </Container>

            </div>
        )
    }
}