import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Tabs } from "antd";
import NotificationDescription from "../Notifications/NotificationDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

class StudentDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        <BreadCrumbOwn title="Student Notifications" />
        {this.props.type == "notification" ? (
          <NotificationDescription code="COLLEGE_ACADEMIC_NOTICE" title="Student Notifications" />
        ) : null}
        {this.props.type == "downloads" ? (
          <NotificationDescription code="COLLEGE_DOWNLOADS" title="Student Downloads" />
        ) : null}
        {this.props.type == "routines" ? <NotificationDescription code="CLASS_ROUTINES" title="Class Routines" /> : null}
        {this.props.type == "results" ? <NotificationDescription code="EXAM_RESULTS" title="Exam Results" /> : null}
        {this.props.type == "exam-schedule" ? <NotificationDescription code="EXAM_SCHEDULE" title="Exam Schedule" /> : null}
        {this.props.type == "clubs" ? <NotificationDescription code="STUDENT_CLUBS" title="Student Clubs" /> : null}
        {this.props.type == "placement_internships" ? <NotificationDescription code="PLACEMENT_INTERNSHIPS" title="Placements & Internships" /> : null}
      </div>
    );
  }
}

export default StudentDescription;

export const Notification = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        {props.title}
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        notifications
      </Typography>
    </div>
  );
};

export const Downloads = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        {props.title}
      </Typography>
      <hr />

      <Typography variant="body1" gutterBottom>
        Downloads
      </Typography>
    </div>
  );
};

const { TabPane } = Tabs;
