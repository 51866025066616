import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { PostData } from "../api/service";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Gallery from 'react-grid-gallery';
import ShowImage from "../component/Department/ShowImage";


export default class PublicationsContainer extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    __loadFile = () => {
        let d = {
            dept_code: 'PDUAM',
            type: "COLLEGE_PUBLICATIONS"
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {

                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__loadFile()
    }
    render() {
        return (
            <div>

                <BreadCrumbOwn title="Publications" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    <div>

                        {Array.isArray(this.state.data) && this.state.data.map((el, index) => <div style={{ backgroundColor: "#FBEEE6", marginBottom: 10 }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <div style={{ border: "solid 2px white" }}>
                                        <ShowImage
                                            files={el.featured_photo}
                                            nos="1"
                                            height="100%"
                                            width="100%"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Typography
                                        variant="overline"


                                    >
                                        Publications Under
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: 700, color: "#2C3E50" }}
                                        gutterBottom
                                    >
                                        <i>{el.title}</i>
                                    </Typography>
                                    <div style={{ padding: 20 }}>
                                        {Array.isArray(el.upload_info) && el.upload_info.length > 0 && el.upload_info[0].fileType == "image" && <Gallery images={[...el.upload_info.map((el1) => ({
                                            src: el1.url,
                                            thumbnail: el1.url
                                        }))]} />}

                                        {Array.isArray(el.upload_info) && el.upload_info.length > 0 && el.upload_info[0].fileType == "file" && <div>
                                            <ul>
                                                <li><a style={{color: 'blue'}} target="_blank" href={el.url}><img
                                                    src={`/images/icons/pdf-icon.png`}
                                                    style={{width: 20}}
                                                /> View File</a></li>
                                            </ul>
                                        </div>}
                                        
                                    </div>
                                </Grid>
                            </Grid>

                        </div>)}
                    </div>
                </Container>
            </div>
        );
    }
}

{/* <Gallery images={[...el.upload_info.map((el1) => ({
                                        src: el1.url,
                                        thumbnail: el1.url
                                    }))]} /> */}