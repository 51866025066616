import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { PostData } from "../api/service";


const items = [
 
  // {
  //   id: 1,
  //   src: "/gallery/header/prag-1.jpg",
  //   altText: "Slide 4",
  // },
  {
    id: 1,
    src: "/gallery/header/prag-2.jpg",
    altText: "Slide 4",
  },
  {
    id: 1,
    src: "/gallery/header/prag-3.jpg",
    altText: "Slide 4",
  },
  {
    id: 1,
    src: "/gallery/header/prag-4.jpg",
    altText: "Slide 4",
  },
  {
    id: 1,
    src: "/gallery/header/prag-5.jpg",
    altText: "Slide 4",
  },
  {
    id: 1,
    src: "/gallery/header/prag-6.jpg",
    altText: "Slide 4",
  },
  {
    id: 1,
    src: "/gallery/header/prag-7.jpg",
    altText: "Slide 4",
  },
  


];

class RecentNews extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, allPhotos: [], isLoaded: false };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.allPhotos.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.allPhotos.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }


  componentDidMount(){
    PostData(`/getphotosforwebsite`,{
      type: "BANNER_IMAGES"
    }).then((resp) => {
      
      this.setState({
        allPhotos: resp,
        isLoaded: true,
        activeIndex: 0
      })
      
    })
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.state.allPhotos.map((item, index) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={index}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img
            alt={"BANNER_IMAGES"}
            src={item}
            style={{objectFit:'contain'}}
            className="carousel-main-image"
          />
          {/* <CarouselCaption
            className="text-danger"
            captionText={item.head}
            captionHeader={item.head}
          /> */}
        </CarouselItem>
      );
    });


    const slidesSingle = [<CarouselItem
      className="custom-tag"
      tag="div"
      key={100}
      onExiting={this.onExiting}
      onExited={this.onExited}
    >
      <img
        alt={"BANNER_IMAGES"}
        src={"/gallery/header/college.jpg"} style={{objectFit:'contain'}}
  
        className="carousel-main-image"
      />
      {/* <CarouselCaption
        className="text-danger"
        captionText={item.head}
        captionHeader={item.head}
      /> */}
    </CarouselItem>]

    return (
      <div style={{ width: "100%" }}>


        <style>
          {`.custom-tag {
                width: 100%;
                {/* max-height: 30vh; */}
                background: white;
              }
              .custom-tag1 {
                display: block;
                width: 100%;
                max-height: 50vh;
                background: #EAECEE;
              }
              .mobile-custom-tag-1{
                display: none;
              }
              @media only screen and (max-width: 600px){
                .custom-tag {
                  
                  width: 100%;
                  max-height: 50vh;
                  object-fit: contain;
                }
                .custom-tag1 {
                  
                  display: none;
                  
                  
                }
                .mobile-custom-tag-1{
                  display: block;
                  background: #EAECEE;
                  object-fit: contain;
                }
              }
              `}
        </style>
        
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
            >
              
              {this.state.isLoaded ? slides : slidesSingle}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
      </div>
    );
  }
}

export default RecentNews;
