import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import BreadCumb from "../Department/BreadCumb";
import NotificationDescription from "./NotificationDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "Administrative Notices",
    link: "/notifications/administrative",
    type: "administrative",
    code: "COLLEGE_ADMIN_NOTICE",
  },
  {
    title: "Academic Notices",
    link: "/notifications/academic",
    type: "academic",
    code: "COLLEGE_ACADEMIC_NOTICE",
  },
  {
    title: "Recent Achievements",
    link: "/notifications/achievements",
    type: "achievements",
    code: "COLLEGE_ACHIEVEMENTS",
  },
  {
    title: "Student Achievements",
    link: "/notifications/sachievements",
    type: "sachievements",
    code: "COLLEGE_STU_ACHIEVEMENTS",
  },
  {
    title: "Employee Achievements",
    link: "/notifications/eachievements",
    type: "eachievements",
    code: "COLLEGE_EMP_ACHIEVEMENTS",
  },
  {
    title: "Downloads",
    link: "/notifications/downloads",
    type: "downloads",
    code: "COLLEGE_DOWNLOADS",
  },
  {
    title: "Tender Notices",
    link: "/notifications/tender",
    type: "tender",
    code: "COLLEGE_TENDER",
  },
];


class NotificationComponent extends Component {
  state = {
    link: "",
    code: "",
    title: "",
    year: "",
    isLoaded: false,
  };
  componentDidMount() {
    this.setState({
      isLoaded: false,
    });
    let link = this.props.match.params.link;
    let year = this.props.match.params.year;
    let r = list.filter((el) => el.type == link);
    if (r.length > 0) {
      this.setState({
        link: link,
        year: year,
        code: r[0].code,
        title: r[0].title,
        isLoaded: true,
      });
    } 
    // else {
    //   this.props.history.replace("/");
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      this.setState({
        isLoaded: false,
      });
      let link = this.props.match.params.link;
      let year = this.props.match.params.year;
      let r = list.filter((el) => el.type == link);
      if (r.length > 0) {
        this.setState(
          {
            link: link,
            year: year,
            code: r[0].code,
            title: r[0].title,
            isLoaded: false,
          },
          () => this.setState({ isLoaded: true })
        );
      } 
      // else {
      //   this.props.history.replace("/");
      // }
    }
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Notifications" />
        {/* <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  background: "",
                  borderRadius: "none",
                }}
              >
                <List component="nav">
                  {list.map((el, index) => (
                    <ListItem
                      button
                      style={{
                        border: "1px solid #0002",
                        marginBottom: "2px",
                        backgroundColor: el.link === `/notifications/${this.state.link}` ? '#F5EB3D' :'#f9f9f9'

                      }}
                      key={index}
                      onClick={() => this.props.history.push(el.link)}
                    >
                      <div
                        style={{
                          fontSize: "1.2em",
                            fontWeight: "700",
                            padding: "0",
                        }}
                      >
                        {el.title}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              
              {this.state.isLoaded ? (
                <NotificationDescription
                  type={this.state.link}
                  year={this.state.year}
                  code={this.state.code}
                  title={this.state.title}
                  history={this.props.history}
                />
              ) : null}
            </Grid>
          </Grid>
        </Container> */}

        {this.state.isLoaded ? (
                <NotificationDescription
                  type={this.state.link}
                  year={this.state.year}
                  code={this.state.code}
                  title={this.state.title}
                  history={this.props.history}
                />
              ) : null}
      </div>
    );
  }
}

export default NotificationComponent;
