import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import BreadCumb from "../Department/BreadCumb";
import ProgramsDescription from "./ProgramsDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

const list = [
  {
    title: "Reports",
    link: "/programs/reports",
    type: "reports",
    code: "COLLEGE_PROGRAM",
  },
  
];


class ProgramsComponent extends Component {
  state = {
    link: "",
    code: "",
    title: "",
    year: "",
    isLoaded: false,
  };
  componentDidMount() {
    this.setState({
      isLoaded: false,
    });
    let link = this.props.match.params.link;
    let year = this.props.match.params.year;
    let r = list.filter((el) => el.type == link);
    if (r.length > 0) {
      this.setState({
        link: link,
        year: year,
        code: r[0].code,
        title: r[0].title,
        isLoaded: true,
      });
    } 
    // else {
    //   this.props.history.replace("/");
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      this.setState({
        isLoaded: false,
      });
      let link = this.props.match.params.link;
      let year = this.props.match.params.year;
      let r = list.filter((el) => el.type == link);
      if (r.length > 0) {
        this.setState(
          {
            link: link,
            year: year,
            code: r[0].code,
            title: r[0].title,
            isLoaded: false,
          },
          () => this.setState({ isLoaded: true })
        );
      } 
      // else {
      //   this.props.history.replace("/");
      // }
    }
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Program Reports" />
        

        {this.state.isLoaded ? (
                <ProgramsDescription
                  type={this.state.link}
                  year={this.state.year}
                  code={this.state.code}
                  title={this.state.title}
                  history={this.props.history}
                />
              ) : null}
      </div>
    );
  }
}

export default ProgramsComponent;
