import React, { Component, Suspense } from "react";
import { Switch, Router, Route, BrowserRouter } from "react-router-dom";
import HomepageContainer from "./containers/HomepageContainer";
import history from "./history";
import "./App.css";
import DepartmentHome from "./component/Department/DepartmentHome";
import ScrollToTop from "./ScrollToTop";
import LazyLoader from "./component/Essentials/LazyLoader";

// const DepartmentHome = React.lazy(() => import('./component/Department/DepartmentHome'));
import "antd/dist/antd.css";
import KkhsouContainer from "./containers/KkhsouContainer";
import FloatingAdmissionNew from "./component/Essentials/FloatingAdmissionNew";

class App extends Component {
  state = {
    showAdmission: false,
    admissionSession: "",
    admissionLink: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    fetch(`/settings/admissionSettings.json`)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status == true) {
          this.setState({
            showAdmission: true,
            admissionSession: resp.session,
            admissionLink: resp.link,
          });
        } else {
          this.setState({
            showAdmission: false,
            admissionSession: "",
            admissionLink: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        {this.state.showAdmission && (
          <FloatingAdmissionNew
            session={this.state.admissionSession}
            link={this.state.admissionLink}
          />
        )}

        <Router history={history}>
          {/* <Router history={history}> */}
          <div>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={HomepageContainer} />
                <Route
                  exact
                  path="/syllabus-course"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/administrations/:link"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/aboutus/:link"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/notifications/:link"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/notifications/:link/:year"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/programs/:link"
                  component={HomepageContainer}
                />
                <Route exact path="/grievances" component={HomepageContainer} />
                <Route
                  exact
                  path="/programs/:link/:year"
                  component={HomepageContainer}
                />
                <Route exact path="/events" component={HomepageContainer} />
                <Route
                  exact
                  path="/singleevent"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/departments"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/student-wings"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/students/:link"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/facility/:link"
                  component={HomepageContainer}
                />
                <Route exact path="/ncc/:link" component={HomepageContainer} />
                <Route exact path="/nss/:link" component={HomepageContainer} />
                <Route exact path="/research" component={HomepageContainer} />
                <Route
                  exact
                  path="/research-projects"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/publications"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/wallmagazines"
                  component={HomepageContainer}
                />
                <Route exact path="/feedbacks" component={HomepageContainer} />
                <Route exact path="/aishe-data" component={HomepageContainer} />
                <Route exact path="/newsletter" component={HomepageContainer} />
                <Route
                  exact
                  path="/research/:link"
                  component={HomepageContainer}
                />
                <Route exact path="/iqac/:link" component={HomepageContainer} />
                <Route exact path="/nirf" component={HomepageContainer} />
                <Route
                  exact
                  path="/visitors-note"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/importantfile"
                  component={HomepageContainer}
                />
                <Route exact path="/emblem" component={HomepageContainer} />
                <Route exact path="/organo" component={HomepageContainer} />
                <Route exact path="/kkhsou" component={HomepageContainer} />
                <Route exact path="/ignou" component={HomepageContainer} />
                <Route exact path="/idol" component={HomepageContainer} />
                <Route exact path="/nptel" component={HomepageContainer} />
                <Route exact path="/course" component={HomepageContainer} />
                <Route
                  exact
                  path="/admission-procedure"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/examination"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/rulesandregulations"
                  component={HomepageContainer}
                />{" "}
                <Route
                  exact
                  path="/evaluationsystem"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/academiccalendar"
                  component={HomepageContainer}
                />
                <Route exact path="/rti/:link" component={HomepageContainer} />
                <Route
                  exact
                  path="/alumni/:link"
                  component={HomepageContainer}
                />
                <Route exact path="/contactus" component={HomepageContainer} />
                <Route exact path="/committees" component={HomepageContainer} />
                <Route exact path="/ncc" component={HomepageContainer} />
                <Route
                  exact
                  path="/department/:dept_code"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/departments/arts"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/departments/science"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/departments/commerce"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/departments/others"
                  component={HomepageContainer}
                />
                {/* <Route exact path="/department/:dept_code/vision" component={DepartmentHome} /> */}
                {/* <Route exact path="/department/:dept_code/glance" component={DepartmentHome} /> */}
                <Route
                  exact
                  path="/department/:dept_code/profile"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/course"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/contact"
                  component={DepartmentHome}
                />
                {/* <Route exact path="/department/:dept_code/others" component={DepartmentHome} /> */}
                {/* <Route exact path="/department/:dept_code/onlineclasses" component={DepartmentHome} /> */}
                <Route
                  exact
                  path="/department/:dept_code/faculty"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/staff"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/co_po"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/wall-magazine"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/faculty/single"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/department/:dept_code/faculty/quarterlyreport"
                  component={HomepageContainer}
                />
                <Route
                  exact
                  path="/department/:dept_code/syllabus"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/journal"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/events"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/notices"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/achievements"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/alumni"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/activity"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/gallery"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/research"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/department/:dept_code/singleevent"
                  component={DepartmentHome}
                />
                <Route
                  exact
                  path="/gallery/:link"
                  component={HomepageContainer}
                />
                {/* <Route exact path="/news" component={HomepageContainer} /> */}
                {/* <Route exact path="/registration" component={HomepageContainer} />
                <Route exact path="/officestaff" component={HomepageContainer} />
                <Route exact path="/supportstaff" component={HomepageContainer} />
                <Route exact path="/librarystaff" component={HomepageContainer} /> */}
                {/* <Route exact path="/aboutus" component={HomepageContainer} /> */}
                {/* <Route exact path="/vision" component={HomepageContainer} />
                <Route exact path="/mission" component={HomepageContainer} />
                <Route exact path="/committee" component={HomepageContainer} />
                <Route exact path="/rti" component={HomepageContainer} />

                <Route exact path="/principal" component={HomepageContainer} />
                <Route exact path="/secretary" component={HomepageContainer} />
                <Route exact path="/viceprincipal" component={HomepageContainer} />
                <Route exact path="/coursecordinator" component={HomepageContainer} />
                <Route exact path="/gbpresident" component={HomepageContainer} />
                <Route exact path="/trimurti" component={HomepageContainer} />

                <Route exact path="/hods" component={HomepageContainer} />
                <Route exact path="/governingbody" component={HomepageContainer} />
                <Route exact path="/managementbody" component={HomepageContainer} />
                <Route exact path="/generalrule" component={HomepageContainer} />

                <Route exact path="/library/:link" component={HomepageContainer} />


                <Route exact path="/infrastructure/:link" component={HomepageContainer} />
                <Route exact path="/academic/:link" component={HomepageContainer} />
                <Route exact path="/others/:link" component={HomepageContainer} />
                <Route exact path="/tenders/:link" component={HomepageContainer} />
                <Route exact path="/publication/:link" component={HomepageContainer} />
                <Route exact path="/facilities/:link" component={HomepageContainer} />
                <Route exact path="/achievements/:link" component={HomepageContainer} />
                <Route exact path="/focus/:link" component={HomepageContainer} />
                <Route exact path="/digital/:link" component={HomepageContainer} />
                <Route exact path="/innovatives/:link" component={HomepageContainer} />
                <Route exact path="/supports/:link" component={HomepageContainer} />
               
                <Route exact path="/extensions/:link" component={HomepageContainer} /> */}
              </Switch>
            </ScrollToTop>
          </div>

          {/* </Router> */}
        </Router>
      </div>
    );
  }
}

export default App;
