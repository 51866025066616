import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { Divider, Button } from 'antd'
import { PostData } from '../../api/service'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function HomePageEvents() {

  const [data, setData] = React.useState([])
  const [mainEvent, setMainEvent] = React.useState(null)

  const [isLoaded, setIsLoaded] = React.useState(false)

  function __loadFile() {
    let d = {
      dept_code: 'PDUAM',
      type: 'COLLEGE_EVENT',
      homepage: true

    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {

        setData(resp)
        if (resp.length > 0) {
          setMainEvent(resp[0])
        }
        setIsLoaded(true)
      })
  }

  React.useEffect(() => {
    __loadFile()
  }, [])
  return (
    <div>
      <div style={{padding: 20}}>
        <Divider orientation="left" style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}>NEWS & EVENTS</Divider>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>

            {isLoaded && mainEvent != null && <div style={{ cursor: "pointer" }}>
              {Array.isArray(mainEvent.featured_photo) && mainEvent.featured_photo.length > 0 && <img
                src={mainEvent.featured_photo[0].url}
                style={{ width: '100%' }}
              />}

              {(Array.isArray(mainEvent.featured_photo) && mainEvent.featured_photo.length == 0) || mainEvent.featured_photo == null && <img
                src="/images/prag-small-overview.jpg"
                style={{ width: '100%' }}
              />}
              <br />
              <br />

              <Typography style={{ fontWeight: 700, fontSize: 14, color: "#808B96" }}>{moment(mainEvent.date, "DD-MM-YYYY").format("DD-MM-YYYY")}</Typography>
              <Typography variant="h6" style={{ fontWeight: 700, fontSize: 14 }} gutterBottom>{mainEvent.title}</Typography>

              <div style={{textAlign: "right"}}>
              <Link to={`/singleevent?eventId=${mainEvent.id}`}>
                <Button size="middle" type="primary">View Event</Button>
              </Link>
              </div>

            </div>}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={2}>
              {isLoaded && Array.isArray(data) && data.map((el, index) => <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                <div style={{ display: "flex", alignItems: "center", backgroundColor: '#EAECEE', height: 80, cursor: "pointer" }}>
                  <div style={{ flexBasis: "30%", height: 80 }}>
                    {Array.isArray(el.featured_photo) && el.featured_photo.length > 0 && <img
                      src={el.featured_photo[0].url}
                      style={{ width: '100%', height: "100%", objectFit: "cover" }}
                    />}

                    {(Array.isArray(el.featured_photo) && el.featured_photo.length == 0) || el.featured_photo == null && <img
                      src="/images/prag-small-overview.jpg"
                      style={{ width: '100%' }}
                    />}
                  </div>
                  <div style={{ padding: 10, flexBasis: "70%" }}>
                    <Typography style={{ fontWeight: 700, fontSize: 12, color: "#808B96" }}>{moment(el.date, "DD-MM-YYYY").format("DD-MM-YYYY")}</Typography>
                    <Link to={`/singleevent?eventId=${el.id}`}><Typography variant="h6" style={{ fontWeight: 700, fontSize: 13, overflow: "hidden", height: 40, textOverflow: "ellipsis" }}>{el.title}</Typography>
                    </Link>
                  </div>
                </div>
              </Grid>)}














              <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right" }}>

                <Link to="/events"><Button type="primary">View All</Button></Link>
              </Grid>
            </Grid>
          </Grid>


        </Grid>
      </div>
    </div>
  )
}
