import React from "react";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Container } from "@material-ui/core";
import NotificationDescription from "../Notifications/NotificationDescription";
export default function SingleNotificationAlikeComponent({
  title,
  code,
  noMinHeight = false,
  showBanner = true
}) {
  return (
    <div>
      <div>
        {showBanner == true && <BreadCrumbOwn title={title} />}
        <Container className="mb-5">
          <NotificationDescription
            type="streategies"
            code={code}
            title={title}
            noMinHeight={noMinHeight}
          />
        </Container>
      </div>
    </div>
  );
}
