import React, { Component } from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class ContactPage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Contact`}
                    />

                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={6} md={6}>

                            <Typography
                                dangerouslySetInnerHTML={{ __html: this.props.data.contact_details }}
                            >

                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={12} lg={6} md={6}>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.836301504616!2d92.35645947536463!3d24.663762378058244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3751d9b5c3c3bbb5%3A0x7493c35e86d1543d!2sPandit%20Deendayal%20Upadhyaya%20Adarsha%20Mahavidyalaya%20Eraligool!5e0!3m2!1sen!2sin!4v1710824043567!5m2!1sen!2sin" width="600" height="400" allowfullscreen="" loading="lazy" style={{border: '0px',maxWidth:'100%'}}></iframe>

                        </Grid>
                    </Grid>

                   
                </Container>
            </div>
        );
    }
}

export default ContactPage;