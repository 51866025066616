import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Typography} from '@material-ui/core'
export default class NIRF extends Component {

  state = {
    data: [],
    isLoaded: false
  }

  __loadFile = () => {
    let d = {
      dept_code: "PDUAM",
      type: "NIRF"
    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true,
        })
      })
  }

  componentDidMount() {
    this.__loadFile();
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="NIRF" />
        <Container style={{ minHeight: '80vh' }}>
          <br />


          {console.log(this.state.data)}
          <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
          NIRF
                    </Typography>
     
          {this.state.isLoaded && this.state.data.length > 0 && <div>
            <Table  className="table-striped">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.state.data) && this.state.data.map((el, index) => <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{el.title}</TableCell>
                  <TableCell>{Array.isArray(el.upload_info) && el.upload_info.length > 0 && <a target="_blank" href={el.upload_info[0].url}>Click Here</a>}</TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </div>}



        </Container>
      </div>
    );
  }
}
