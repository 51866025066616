import React, { Component } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import TitleStyle from "../TitleStyle";
import {
  DribbbleOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LinkedinOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { SERVER_UPLOAD } from "../../api/service";
import FacultyQReportFromMain from "./FacultyQReportFromMain";

class FacultyView extends Component {
  state = {
    title: "",
    active: "",
    value: null,
    isLoaded: false,
  };
  componentDidMount() {
    this.setState({
      ...this.props,
      isLoaded: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        ...this.props,
        isLoaded: true,
      });
    }
  }
  render() {
    return (
      <div style={{ overflowX: "auto" }}>
        {this.state.active == "1" && this.state.isLoaded && (
          <>
            <TitleStyle title={this.state.title} />

            <br />

            <div>
              <Typography variant="h6">
                <i>{this.state.name}</i>
              </Typography>
              <Typography variant="subtitle2">
                {this.state.designation}
              </Typography>
              <Typography variant="body2">
                {this.state.email != "" ? (
                  <span>
                    Email:{" "}
                    <a href={`mailto:${this.state.email}`}>
                      {this.state.email}
                    </a>
                    <br />
                  </span>
                ) : null}
                {this.state.phone != "" ? (
                  <span>
                    Phone :{" "}
                    <a href={`tel:${this.state.phone}`}>{this.state.phone}</a>
                  </span>
                ) : null}
              </Typography>
              <Typography variant="body2">
                {this.state.experience != undefined
                  ? `Experience: ${this.state.experience}`
                  : null}{" "}
                &nbsp;&nbsp;
                {this.state.date_of_joining != ""
                  ? `Date of Joining: ${this.state.date_of_joining}`
                  : null}
                {this.state.date_of_leaving != "" &&
                this.state.date_of_leaving != null
                  ? ` - ${this.state.date_of_joining}`
                  : null}{" "}
                <br />
                {this.state.area_of_research != ""
                  ? `Area of Research: ${this.state.area_of_research}`
                  : null}
              </Typography>
            </div>

            <br />

            {this.state.about != "" ? (
              <div style={{ height: "25vh", overflowY: "scroll" }}>
                About me:
                <Typography
                  dangerouslySetInnerHTML={{ __html: this.state.about }}
                  gutterBottom
                ></Typography>
              </div>
            ) : null}
          </>
        )}

        {this.state.active == "2" && (
          <div>
            <TitleStyle title={this.state.title} />

            <br />
            <div>
              Present Address
              <Typography
                dangerouslySetInnerHTML={{ __html: this.state.address }}
                style={{ fontSize: 12, lineHeight: 0.5 }}
              ></Typography>
            </div>
            <br />
            <div>
              Permanent Address
              <Typography
                dangerouslySetInnerHTML={{
                  __html: this.state.permanent_address,
                }}
                style={{ textAlign: "justify" }}
              ></Typography>
            </div>
          </div>
        )}

        {this.state.active == "3" && (
          <>
            <TitleStyle title={this.state.title} />

            <br />
            <Typography
              dangerouslySetInnerHTML={{ __html: this.state.contact }}
              style={{ textAlign: "justify" }}
            ></Typography>
          </>
        )}

        {this.state.active == "12" && (
          <>
            <TitleStyle title={this.state.title} />

            <br />

            {this.state.others != null && (
              <ul style={{ listStyleType: "none" }}>
                {this.state.others.facebook_link != undefined && (
                  <li>
                    <a
                      href={this.state.others.facebook_link}
                      target="_blank"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FacebookOutlined /> &nbsp;&nbsp;Facebook Link
                    </a>
                  </li>
                )}

                {this.state.others.linkedin_link != "" && (
                  <li>
                    <a
                      href={this.state.others.linkedin_link}
                      target="_blank"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <LinkedinOutlined /> &nbsp;&nbsp;LinkedIn Link
                    </a>
                  </li>
                )}

                {this.state.others.google_scholar != "" && (
                  <li>
                    <a
                      href={this.state.others.google_scholar}
                      target="_blank"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <GoogleOutlined /> &nbsp;&nbsp;Google Scholars' Link
                    </a>
                  </li>
                )}
                {this.state.others.wiki_link != "" && (
                  <li>
                    <a
                      href={this.state.others.wiki_link}
                      target="_blank"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="https://en.wikipedia.org/static/images/project-logos/enwiki-2x.png"
                        style={{ width: 15, height: 15 }}
                      />{" "}
                      &nbsp;&nbsp;Wikipedia Link
                    </a>
                  </li>
                )}

                {this.state.others.orchid_link != "" && (
                  <li>
                    <a href={this.state.others.orchid_link} target="_blank">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2FB5FB",
                        }}
                      >
                        <img
                          src="/images/icons/orchid-logo.png"
                          style={{ width: 15, height: 15 }}
                        />{" "}
                        &nbsp;&nbsp;Orcid ID :{" "}
                        <a style={{ fontWeight: 500 }}>
                          {this.state.others.orchid_link}
                        </a>
                      </div>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </>
        )}

        {/* {this.state.active == '4' &&  <>
       <TitleStyle
            title={this.state.title}
          />
          <FacultyQReportFromMain activities={this.state.activities?.data} /></>} */}

        {this.state.active == "4" && (
          <>
            <br />
            {Array.isArray(this.state.seminar) &&
              this.state.seminar.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td>#</td>

                      <td>Title of the seminar/workshop/conference</td>
                      <td>Organized by</td>
                      <td>Title of the paper presented</td>

                      <td>Sponsored by</td>
                      <td>Date & Year</td>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(this.state.seminar) &&
                      this.state.seminar.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.theme}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.organised_by}</td>
                          <td>{el.title}</td>
                          <td>{el.sponsored_by}</td>
                          <td>{el.date_year}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
          </>
        )}

        {this.state.active == "5" && (
          <>
            {Array.isArray(this.state.research) &&
              this.state.research.length > 0 && (
                <>
                  {" "}
                  <TitleStyle title={this.state.title} />
                  <br />
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Title of the project</td>
                        <td>Funding Agency</td>
                        <td>Duration of the Project</td>
                        <td>Project Cost (in Lakh)</td>
                        <td>Co-investigator (if any)</td>
                        <td>Status</td>
                        <td>Date of Commencement</td>
                        <td>Date of Completion</td>
                        <td>Year/Quarter</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.research) &&
                        this.state.research.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {el.title}
                              <br />
                              {Array.isArray(el.file) &&
                                el.file.map((el1, index1) => (
                                  <div key={index1}>
                                    {el1.mime != "link" ? (
                                      <a
                                        target="_blank"
                                        href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                        style={{ color: "blue", fontSize: 10 }}
                                      >
                                        <LinkOutlined /> Attachment {index1 + 1}
                                      </a>
                                    ) : (
                                      <a
                                        target="_blank"
                                        href={`${el1.path}`}
                                        style={{ color: "blue", fontSize: 10 }}
                                      >
                                        <LinkOutlined /> Attachment {index1 + 1}
                                      </a>
                                    )}
                                  </div>
                                ))}
                            </td>
                            <td>{el.sponsored_by}</td>
                            <td>{el.duration}</td>
                            <td>{el.fund}</td>
                            <td>{el.co_researcher}</td>
                            <td>{el.status}</td>
                            <td>{el.commencement_date}</td>
                            <td>{el.submission_date}</td>
                            <td>{el.quarter}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
          </>
        )}

        {this.state.active == "6" && (
          <div>
            <TitleStyle title={this.state.title} />

            <br />

            {Array.isArray(this.state.research_paper) &&
              this.state.research_paper.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Research Publications
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the paper</td>

                      <td>Name of the Book/Journal</td>
                      <td>Published By</td>
                      <td>ISBN/ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Date / Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.research_paper) &&
                      this.state.research_paper.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.other_publication) &&
              this.state.other_publication.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Other Publication with details
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the Publication</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td>Any other information</td>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(this.state.other_publication) &&
                      this.state.other_publication.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>
                          <td>{el.other_info}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.journal) &&
              this.state.journal.length > 0 && (
                <>
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td colSpan={6} align="center">
                          <Typography>
                            Seminar/Research Paper in Journals
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>#</td>

                        <td>Date / Year</td>

                        <td>Title of the paper</td>

                        <td>Title of the Journal</td>
                        <td>Published By</td>
                        <td>ISSN</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.journal) &&
                        this.state.journal.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.date_year}</td>
                            <td>{el.title_paper}</td>
                            <td>{el.title_book}</td>
                            <td>{el.published_by}</td>
                            <td>{el.issn_no}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}

            {Array.isArray(this.state.text_book) &&
              this.state.text_book.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Text Book/Book
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Name of the Book/Text Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>

                      <td>Edition</td>
                      <td>Name of authors</td>
                      <td>Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.text_book) &&
                      this.state.text_book.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>

                          <td>{el.edition}</td>
                          <td>{el.name_of_authors}</td>
                          <td>{el.date_year}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.magazine) &&
              this.state.magazine.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={7} align="center">
                        Book/Journal/ Magazine Edited
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the Book/journal/Magazine</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td>Any other Information</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.magazine) &&
                      this.state.magazine.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>

                          <td>{el.other_info}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.popular_book) &&
              this.state.popular_book.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Article in Popular Book/Magazine/Edited Book
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the paper</td>

                      <td>Title of the Book/Magazine/Edited Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.popular_book) &&
                      this.state.popular_book.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td>{el.date_year}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.news_paper) &&
              this.state.news_paper.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={5} align="center">
                        Publication in News Paper
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>
                      <td>Date / Year</td>

                      <td>Title of the Article</td>

                      <td>Name of the News Paper</td>
                      <td>Year/Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.news_paper) &&
                      this.state.news_paper.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {el.date_year}</td>
                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.econtent) &&
              this.state.econtent.length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={7} align="center">
                        E-CONTENT DEVELOPMENT
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>
                      <td>Year</td>

                      <td>Type of E-content</td>
                      <td>Title of the Publication</td>
                      <td>Published By</td>

                      <td>Remarks</td>
                      <td>Year/Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.econtent) &&
                      this.state.econtent.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {el.date_year}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  {el1.mime != "link" ? (
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  ) : (
                                    <a
                                      target="_blank"
                                      href={`${el1.path}`}
                                      style={{ color: "blue", fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  )}
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.other_info}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
          </div>
        )}

        {this.state.active == "7" &&
          Array.isArray(this.state.workshop) &&
          this.state.workshop.length > 0 && (
            <>
              <TitleStyle title={this.state.title} />

              <br />
              <table width="100%" className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Date & Year</td>
                    <td>Organized by</td>
                    <td>Title</td>
                    <td>Duration</td>

                    <td>Sponsored by</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(this.state.workshop) &&
                    this.state.workshop.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el.date_year}</td>
                        <td>{el.organised_by}</td>
                        <td>
                          {el.theme}
                          <br />
                          {Array.isArray(el.file) &&
                            el.file.map((el1, index1) => (
                              <div key={index1}>
                                {el1.mime != "link" ? (
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: "blue", fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={`${el1.path}`}
                                    style={{ color: "blue", fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                )}
                              </div>
                            ))}
                        </td>
                        <td>{el.duration}</td>
                        <td>{el.sponsored_by}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}

        {this.state.active == "8" &&
          Array.isArray(this.state.bDegree) &&
          this.state.bDegree.length > 0 && (
            <div>
              <TitleStyle title={this.state.title} />

              <br />

              {Array.isArray(this.state.bDegree) &&
                this.state.bDegree.length > 0 &&
                this.state.bDegree[0].course_b != "" && (
                  <>
                    <table
                      width="100%"
                      className="table table-sm table-bordered"
                    >
                      <thead>
                        <tr>
                          <td colSpan={4} align="center">
                            Bachelor Degree Details
                          </td>
                        </tr>
                        <tr>
                          <td>Course</td>
                          <td>Subject</td>
                          <td>University/Institution</td>
                          <td>Year</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.bDegree) &&
                          this.state.bDegree.map((el, index) => (
                            <tr key={index}>
                              <td>{el.course_b}</td>
                              <td>{el.subject_b}</td>
                              <td>{el.university_b}</td>
                              <td>{el.year_b}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}

              {Array.isArray(this.state.mDegree) &&
                this.state.mDegree.length > 0 &&
                this.state.mDegree[0].course_m != "" && (
                  <>
                    <table
                      width="100%"
                      className="table table-sm table-bordered"
                    >
                      <thead>
                        <tr>
                          <td colSpan={4} align="center">
                            Master Degree Details
                          </td>
                        </tr>
                        <tr>
                          <td>Course</td>
                          <td>Subject</td>
                          <td>University/Institution</td>
                          <td>Year</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.mDegree) &&
                          this.state.mDegree.map((el, index) => (
                            <tr key={index}>
                              <td>{el.course_m}</td>
                              <td>{el.subject_m}</td>
                              <td>{el.university_m}</td>
                              <td>{el.year_m}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}

              {Array.isArray(this.state.phd) &&
                this.state.phd.length > 0 &&
                this.state.phd[0].title_p != "" && (
                  <>
                    <table
                      width="100%"
                      className="table table-sm table-bordered"
                    >
                      <thead>
                        <tr>
                          <td colSpan={5} align="center">
                            PhD Details
                          </td>
                        </tr>
                        <tr>
                          <td>Title of the PhD Research</td>
                          <td>Year of Receiving Award</td>
                          <td>University</td>
                          <td>Department</td>

                          <td>Name of the Supervisor</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.phd) &&
                          this.state.phd.map((el, index) => (
                            <tr key={index}>
                              <td>{el.title_p}</td>
                              <td>{el.year_p}</td>
                              <td>{el.university_p}</td>
                              <td>{el.department_p}</td>
                              <td>{el.supervisor_p}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}

              {Array.isArray(this.state.mphil) &&
                this.state.mphil.length > 0 &&
                this.state.mphil[0].title_m != "" && (
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td colSpan={5} align="center">
                          M. Phil Details
                        </td>
                      </tr>
                      <tr>
                        <td>Title of the M. Phil Research</td>
                        <td>Year of Receiving M. Phil Degree</td>
                        <td>University</td>
                        <td>Department</td>

                        <td>Name of the Supervisor</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.mphil) &&
                        this.state.mphil.map((el, index) => (
                          <tr key={index}>
                            <td>{el.title_m1}</td>
                            <td>{el.year_m1}</td>
                            <td>{el.university_m1}</td>
                            <td>{el.department_m1}</td>
                            <td>{el.supervisor_m1}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

              {Array.isArray(this.state.oDegree) &&
                this.state.oDegree.length > 0 && (
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td colSpan={5} align="center">
                          Other Details
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>Year</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.oDegree) &&
                        this.state.oDegree.map((el, index) => (
                          <tr key={index}>
                            <td>{el.description_o}</td>
                            <td>{el.year_o}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
            </div>
          )}

        {this.state.active == "9" &&
          ((Array.isArray(this.state.college_activities) &&
            this.state.college_activities.length > 0) ||
            (Array.isArray(this.state.social_activities) &&
              this.state.social_activities.length > 0)) && (
            <div>
              <TitleStyle title={this.state.title} />

              <br />
              {Array.isArray(this.state.college_activities) &&
                this.state.college_activities.length > 0 && (
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td colSpan={6} align="center">
                          In Campus Activities
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>#</td>
                        <td rowSpan={2}>Name of the Committee / Scheme</td>

                        <td rowSpan={2}>
                          Position/Responsibility/ Capacity Hold
                        </td>

                        <td colSpan={2}>Period</td>
                        <td rowSpan={2}>Other Information</td>
                      </tr>
                      <tr>
                        <td>From</td>
                        <td>To</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.college_activities) &&
                        this.state.college_activities.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td> {el.name}</td>
                            <td>{el.position}</td>
                            <td>{el.from}</td>
                            <td>{el.till}</td>
                            <td>{el.other_info}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

              {Array.isArray(this.state.social_activities) &&
                this.state.social_activities.length > 0 && (
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td colSpan={7} align="center">
                          Extension Activities
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>#</td>
                        <td rowSpan={2}>Name of the Committee / Scheme</td>

                        <td rowSpan={2}>
                          Position/Responsibility/ Capacity Hold
                        </td>

                        <td colSpan={2}>Period</td>
                        <td rowSpan={2}>Other Information</td>
                        <td rowSpan={2}>Year & Quarter</td>
                      </tr>
                      <tr>
                        <td>From</td>
                        <td>To</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.social_activities) &&
                        this.state.social_activities.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {" "}
                              {el.name}
                              {Array.isArray(el.file) &&
                                el.file.map((el1, index1) => (
                                  <div key={index1}>
                                    {el1.mime != "link" ? (
                                      <a
                                        target="_blank"
                                        href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                        style={{ color: "blue", fontSize: 10 }}
                                      >
                                        <LinkOutlined /> Attachment {index1 + 1}
                                      </a>
                                    ) : (
                                      <a
                                        target="_blank"
                                        href={`${el1.path}`}
                                        style={{ color: "blue", fontSize: 10 }}
                                      >
                                        <LinkOutlined /> Attachment {index1 + 1}
                                      </a>
                                    )}
                                  </div>
                                ))}
                            </td>
                            <td>{el.position}</td>
                            <td>{el.from}</td>
                            <td>{el.till}</td>
                            <td>{el.other_info}</td>
                            <td>{el.quarter}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
            </div>
          )}

        {this.state.active == "10" &&
          Array.isArray(this.state.achievements) &&
          this.state.achievements.length > 0 && (
            <>
              <TitleStyle title={this.state.title} />

              <br />
              <table width="100%" className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td>#</td>
                    {/* <td>Area</td> */}
                    <td>Description</td>
                    {/* <td>Award</td> */}
                    <td>Year</td>
                    <td>Other Information</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(this.state.achievements) &&
                    this.state.achievements.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {/* <td> {el.area}</td> */}
                        <td>{el.achievement}</td>
                        {/* <td>{el.award}</td> */}
                        <td>{el.date_year}</td>
                        <td>{el.other_info}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}

        {this.state.active == "11" &&
          Array.isArray(this.state.awards) &&
          this.state.awards.length > 0 && (
            <>
              <TitleStyle title={this.state.title} />

              <br />
              <table width="100%" className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Name of the Award</td>
                    <td>Given By</td>
                    <td>Year</td>
                    <td>Other Information</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(this.state.awards) &&
                    this.state.awards.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {" "}
                          {el.area}
                          <br />
                          {Array.isArray(el.file) &&
                            el.file.map((el1, index1) => (
                              <div key={index1}>
                                {el1.mime != "link" ? (
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: "blue", fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={`${el1.path}`}
                                    style={{ color: "blue", fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                )}
                              </div>
                            ))}
                        </td>
                        <td>{el.achievement}</td>
                        <td>{el.award}</td>
                        <td>{el.other_info}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}

        {this.state.active == "13" &&
          Array.isArray(this.state.guideship) &&
          this.state.guideship.length > 0 && (
            <>
              <TitleStyle title={this.state.title} />

              <br />
              <table width="100%" className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Name of the Scholars</td>
                    <td>Date of Registration</td>
                    <td>Topic of Research</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(this.state.guideship) &&
                    this.state.guideship.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td> {el.name}</td>
                        <td>{el.date_of_registration}</td>
                        <td>{el.topic}</td>
                        <td>{el.status}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
      </div>
    );
  }
}

export default FacultyView;
