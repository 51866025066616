import React, { Component } from "react";
import { Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import CommiteeCode from "../Committee/CommiteeCode";
import IqacMember from "./IqacMemeber";
import { GetData, PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";
import IqacMatrics from "./IqacMatrics";
import IqacEvents from "../../containers/IqacEvents";

const initialState = [];
class IqacDescription extends Component {
  render() {
    return (
      <div>
        {this.props.type == "about" ? <About /> : null}
        {this.props.type == "matrix" ? <Building2 type="strategies" code="IQAC_STRATEGIES" title="IQAC Strategies" /> : null}
        {/* {this.props.type == "strategies" ? <NotificationDescription type="strategies" code="IQAC_STRATEGIES" title="IQAC Strategies" /> : null}
        {this.props.type == "functions" ? <NotificationDescription type="streategies" code="IQAC_FUNCTIONS" title="IQAC Functions" /> : null} */}
        {this.props.type == "teams" ? <CommiteeCode committee_code="IQAC" /> : null}
        {this.props.type == "ssr" ? <NotificationDescription type="streategies" code="IQAC_SSR" title="IQAC Self Study Reports" /> : null}
        {this.props.type == "aqar" ? <NotificationDescription type="streategies" code="IQAC_AQAR" title="IQAC AQAR" /> : null}
        {this.props.type == "atr" ? <NotificationDescription type="streategies" code="IQAC_ATR" title="IQAC Action taken Report & Meeting Minutes" /> : null}


        {this.props.type == "actionplan" ? <NotificationDescription type="streategies" code="IQAC_ACTION_PLAN" title="Action Plan" /> : null}

        {this.props.type == "units-and-wings" ? <NotificationDescription type="streategies" code="IQAC_UNITS_AND_WINGS" title="Units & Wings" /> : null}

        {this.props.type == "core-committes" ? <NotificationDescription type="streategies" code="IQAC_CORE_COMMITTEES" title="IQAC Core Committees" /> : null}


        {this.props.type == "aishe-data" ? <div>
          <NotificationDescription type="streategies" code="IQAC_AISHE_DATA" title="AISHE Data" noMinHeight={true} />
          <NotificationDescription type="streategies" code="IQAC_AISHE_DATA_C" title="AISHE Certificates" noMinHeight={true} />
        </div> : null}


        {this.props.type == "mou-collaborations" ? <NotificationDescription type="streategies" code="IQAC_MOUS_AND_COLLABORATIONS" title="MoUs & Collaborations" /> : null}


        {this.props.type == "handbook" ? <NotificationDescription type="streategies" code="IQAC_HANDBOOK" title="Handbook" /> : null}

        {this.props.type == "dac" ? <NotificationDescription type="streategies" code="IQAC_DAC" title="DAC" /> : null}

        {this.props.type == "iiqa" ? <NotificationDescription type="streategies" code="IQAC_IIQA" title="IIQA" /> : null}

        {this.props.type == "sdf" ? <NotificationDescription type="streategies" code="IQAC_SDF" title="SDF" /> : null}





        {this.props.type == "minutes" ? <NotificationDescription type="streategies" code="IQAC_MIUNTES" title="IQAC Minutes" /> : null}
        {this.props.type == "feedback" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_FORM" title="IQAC Feedback Form" /> : null}
        {this.props.type == "feedback-analysis" ? <div>
          <NotificationDescription noMinHeight={true} type="streategies" code="IQAC_FEEDBACK_ANALYSIS_STUDENTS" title="IQAC Feedback Analysis(Students)" />
          <NotificationDescription noMinHeight={true} type="streategies" code="IQAC_FEEDBACK_ANALYSIS_ALUMNI" title="IQAC Feedback Analysis(Alumni)" />
          <NotificationDescription noMinHeight={true} type="streategies" code="IQAC_FEEDBACK_ANALYSIS_PARENTS" title="IQAC Feedback Analysis(Parents)" />
          <NotificationDescription noMinHeight={true} type="streategies" code="IQAC_FEEDBACK_ANALYSIS_TEACHINGSTAFF" title="IQAC Feedback Analysis(Teaching Staff)" />
          <NotificationDescription noMinHeight={true} type="streategies" code="IQAC_FEEDBACK_ANALYSIS_NONTEACHINGSTAFF" title="IQAC Feedback Analysis(Non Teaching Staff)" />
        </div> : null}
        {this.props.type == "sss" ? <NotificationDescription type="streategies" code="IQAC_SSS" title="IQAC Student Satisfactory Survey" /> : null}
        {this.props.type == "event-news" ? <IqacEvents /> : null}
        {this.props.type == "co_po" ? <Building3 type="streategies" code="IQAC_EVENT" title="IQAC News/Event" /> : null}
        {this.props.type == "best-practice" ? <NotificationDescription type="streategies" code="IQAC_BEST_PRACTICE" title="Best Practice" /> : null}
        {this.props.type == "institutional-distingtiveness" ? <NotificationDescription type="streategies" code="IQAC_INSTITUTIONAL_DISTINGTIVENESS" title="Institutional Distinctiveness" /> : null}
        {this.props.type == "annual-report" ? <NotificationDescription type="streategies" code="IQAC_ANNUAL_REPORT" title="Annual Report" /> : null}
        {this.props.type == "academic-calendar" ? <NotificationDescription type="streategies" code="IQAC_ACADEMIC_CALENDAR" title="Academic Canlendar" /> : null}
        {this.props.type == "organogram" ? <NotificationDescription type="streategies" code="IQAC_ORGANOGRAM" title="Organogram" /> : null}
        {this.props.type == "ssr" ? <NotificationDescription type="streategies" code="IQAC_SSR" title="SSR" /> : null}
        {this.props.type == "dvv" ? <NotificationDescription type="streategies" code="IQAC_DVV" title="DVV" /> : null}
        {this.props.type == "cgc" ? <NotificationDescription type="streategies" code="IQAC_CGC" title="Codes, Guidelines & Circulars" /> : null}
        {this.props.type == "auditreport" ? <NotificationDescription type="streategies" code="IQAC_AUDIT" title="Audit Report" /> : null}
        {this.props.type == "nirf" ? <NirfSmall /> : null}
      </div>
    );
  }
}

export default IqacDescription;



export const About = (props) => {

  // fetch the data 
  const [data, setData] = React.useState(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    let d = {
      dept_code: 'PDUAM',
      type: "IQAC_ABOUT",
  }

  PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {

        if(resp.length > 0){
          setData(resp[0])
          setIsLoaded(true)
        }
          
      })
  }, [])

  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        About
      </Typography>

      {isLoaded && data && <div dangerouslySetInnerHTML={{__html: data.description}}></div>}

     

    </div>
  );
};

export const Building1 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        IQAC Members
      </Typography>
      <IqacMember data={initialState} />
    </div>
  );
};


export const Building2 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        NAAC Matrices
      </Typography>
      <IqacMatrics />
    </div>
  );
};


export const Building3 = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
      setData(resp)
      setIsLoaded(true)

    })
  }, [])
  return (
    <div className='mb-4'>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        All COs & POs
      </Typography>


      {isLoaded && <div>

        <ul>
          {Array.isArray(data) && data.map((el, index) => <li key={index}>
            <a style={{ fontSize: 14 }} targte="_blank" href={`/department/${el.dept_code}/co_po`}>COs & POs list of {el.dept_name}</a>
          </li>)}
        </ul>
      </div>}


    </div>
  )
}


export const NirfSmall = () => {
  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    let d = {
      dept_code: "PDUAM",
      type: "NIRF"
    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {
        setData(resp)
        setIsLoaded(true)
      })
  }, [])
  return (
    <div className='mb-4'>
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        NIRF Data
      </Typography>


      {isLoaded && data.length > 0 && <div>
            <Table  className="table-striped">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && data.map((el, index) => <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{el.title}</TableCell>
                  <TableCell>{Array.isArray(el.upload_info) && el.upload_info.length > 0 && <a target="_blank" href={el.upload_info[0].url}>Click Here</a>}</TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </div>}


    </div>
  )
}