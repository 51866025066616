import React, { Component } from "react";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { GetData, PostData } from "../../api/service";
import { Card, CardBody } from "reactstrap";

class GrievanceDescription extends Component {
  render() {
    return (
      <div>
        <Library title="Grievances" type="COLLEGE_GRIEVANCES" />
      </div>
    );
  }
}

export default GrievanceDescription;


export const Library = (props) => {

  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    __loadFile()
  }, [])

  function __loadFile() {
    let d = {
      dept_code: 'PDUAM',
      type: props.type,
      year: ""
    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {
        setData(resp)
        setIsLoaded(true)
      })
  }



  return (
    <>
      
      <Container className="mb-5">
        <div className="text-justify">
          <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
          {props.title}
          </Typography>


          {isLoaded && <div>
            {Array.isArray(data) && data.map((el, index) => <div key={index}>
              <Typography variant="body1" gutterBottom>
                <p variant="body1" dangerouslySetInnerHTML={{ __html: el.description }} />
              </Typography>

              <br />

              <Grid container spacing={2}>
                {Array.isArray(el.upload_info) && el.upload_info.map((el, index) => <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                  {el.fileType != "PDF" && <img
                    src={el.url}
                    style={{ width: '100%' }}
                  />}


                  {el.fileType == "PDF" && <a target="_blank" href={el.url}>{el.path}</a>}
                </Grid>)}
              </Grid>

            </div>)}
          </div>}
        </div>
      </Container>
    </>
  );
};