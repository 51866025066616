import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { PostData } from "../api/service";

export default class ExaminationContainer extends Component {
    state = {
        data: "",
        isLoaded: false,
      };
    
      componentDidMount() {
        let d = {
          dept_code: "PDUAM",
          type: "EXAMINATION",
        };
    
        PostData(`/getnoticesbytypedept`, d).then((resp) => {
          if (resp.length > 0) {
            this.setState({
              data: resp[0],
              isLoaded: true,
            });
          }
        });
      }
    render() {
        return (
            <div>

                <BreadCrumbOwn title="Examination" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    {this.state.isLoaded && this.state.data && <div className="prose" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>}
                    <div>
                    <Typography variant="body2"></Typography>

                    </div>
                </Container>
            </div>
        );
    }
}
