import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import GrievanceDescription from "./GrievanceDescription";
import BreadCumb from "../Department/BreadCumb";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Menu } from "antd";



class GrievanceComponent extends Component {
  state = {
    link: "",
  };
  
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Grievances" />
        <Container style={{ padding: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <GrievanceDescription  />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default GrievanceComponent;
