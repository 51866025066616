import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PanduNav from "../component/HeaderComponent/PanduNav";
import HomePagePandu from "../component/HomePagePandu";
import PanduFooter from "../component/HeaderComponent/PanduFooter";
import PanduFacility from "../component/PanduFacility/PanduFacility";
import NIRF from "./NIRF";
import Events from "./Events";
import PanduAbout from "../component/PanduAbout/PanduAbout";
import NotificationComponent from "../component/Notifications/NotificationComponent";

import IqacComponent from "../component/IQAC/IqacComponent";
import AlumniComponent from "../component/Alumni/AlumniComponent";
import ContactUsContainer from "./ContactUsContainer";
import DepertmentContainer from "./DepertmentContainer";
import StreamsContainer from "./StreamsContainer";
import PanduStudents from "../component/Students/PanduStudents";
import CommitteesContainer from "./CommitteesContainer";
import AdministrationContainer from "../component/Administration/AdministrationContainer";
import RtiContainer from "../component/Rti/RtiContainer";
import FacultySingleLanding from "../component/FacultySingle/FacultySingleLanding";
import FacultyQuarterlyReportLanding from "../component/FacultySingle/FacultyQuarterlyReportLanding";
import ResearchContainer from "../component/Reasearch/ResearchContainer";
import Importantfiles from "./Importantfiles";
import EmblemContainers from "./EmblemContainers";
import OrganogramContainer from "./OrganogramContainer";
import KkhsouContainer from "./KkhsouContainer";
import IgnouContainer from "./IgnouContainer";
import IdolContainer from "./IdolContainer";
import AdmissionProcedureContainer from "./AdmissionProcedureContainer";
import ExaminationContainer from "./ExaminationContainer";
import RulesAndRegulationsContainer from "./RulesAndRegulationsContainer";
import GalleryComponent from "./../component/Gallery/GalleryComponent";
import SingleEvent from "./SingleEvent";
import PublicationsContainer from "./PublicationsContainer";
import RecruitmentContainer from "./ReceuitmentContainer";
import NptelChapter from "../component/Nptel/NptelChapter";
import VisitorsNoteContainer from "./VisitorsNoteContainer";
import StudentWingsContainer from "./StudentWingsContainer";
import SyllabusContainer from "./SyllabusContainer";
import WallmagazineContainer from "./WallmagazineContainer";
import NewsletterContainer from "./NewsletterContainer";
import ResearchProjects from "../component/ResearchProjects/ResearchProjects";
import NccContainer from "./NccContainer";
import NccFacility from "../component/Ncc/NccFacility";
import NssFacility from "../component/Nss/NssFacility";
import AcademicCalendarContainer from "./AcademicCalendarContainer";
import EvaluationSystemContainer from "./EvaluationSystemContainer";
import ProgramsComponent from "../component/Programs/ProgramsComponent";
import GrievanceComponent from "../component/Grievance/GrievanceComponent";
import CourseContainer from "./CourseContainer";
import SingleNotificationAlikeComponent from "../component/SingleNotificationAlike/SingleNotificationAlikeComponent";

// const PanduAbout = React.lazy(() => import('../component/PanduAbout/PanduAbout'));
// const NotificationComponent = React.lazy(() => import('../component/Notifications/NotificationComponent'));
// const IqacComponent = React.lazy(() => import('../component/IQAC/IqacComponent'));
// const AlumniComponent = React.lazy(() => import('../component/Alumni/AlumniComponent'));
// const ContactUsContainer = React.lazy(() => import('./ContactUsContainer'));
// const NIRF = React.lazy(() => {
//   return import('./NIRF.js')
// });

// const FullNews = React.lazy(() => import('../component/News/FullNews'));
// const FullTender = React.lazy(() => import('../component/News/FullTender'));
// const InfrastructureComponent = React.lazy(() => import('../component/Instrastructure/InfrastructureComponent'));
// const AcademicComponent = React.lazy(() => import('../component/Academics/AcademicComponent'));
// const OthersComponent = React.lazy(() => import('../component/Others/OthersComponent'));

// const OfficeStaff = React.lazy(() => import('../component/Administration/OfficeStaff'));
// const SupportingStaff = React.lazy(() => import('../component/Administration/SupportingStaff'));
// const LibraryStaff = React.lazy(() => import('../component/Library/LibraryStaff'));
// const AboutUsContainer = React.lazy(() => import('./AboutUsContainer'));
// const VisionContainer = React.lazy(() => import('./VisionContainer'));
// const MissionContainer = React.lazy(() => import('./MissionContainer'));
// const DepartmentsContainer = React.lazy(() => import('./DepartmentsContainer'));
// const LibraryContainer = React.lazy(() => import('./LibraryContainer'));
// const RtiContainer = React.lazy(() => import('./RtiContainer'));
// const PrincipalContainer = React.lazy(() => import('../component/Administration/PrincipalContainer'));
// const GbContainer = React.lazy(() => import('../component/Administration/GbContainer'));
// const ViceprincipalContainer = React.lazy(() => import('../component/Administration/ViceprincipalContainer'));
// const HodContainer = React.lazy(() => import('../component/Administration/HodContainer'));
// const GoverningBody = React.lazy(() => import('../component/Administration/GoverningBody'));
// const ManagementBody = React.lazy(() => import('../component/Administration/ManagementBody'));
// const GeneralRule = React.lazy(() => import('../component/Administration/GeneralRule'));
// const InnovativePracticesComponent = React.lazy(() => import('../component/InnovativePractices/InnovativePracticesComponent'));
// const FocusComponent = React.lazy(() => import('../component/Focus/FocusComponent'));
// const AchievementsComponent = React.lazy(() => import('../component/Achievements/AchievementsComponent'));
// const FacilitiesComponent = React.lazy(() => import('../component/Facilities/FacilitiesComponent'));
// const PublicationComponent = React.lazy(() => import('../component/Publication/PublicationComponent'));
// const LibraryComponent = React.lazy(() => import('../component/Library/LibraryComponent'));

// const ExtensionComponent = React.lazy(() => import('../component/Extensions/ExtensionComponent'));
// const SupportComponent = React.lazy(() => import('../component/Support/SupportComponent'));
// const GalleryComponent = React.lazy(() => import('../component/Gallery/GalleryComponent'));
// const CourseCordinatorContainer = React.lazy(() => import('../component/Administration/CourseCordinatorContainer'));
// const GbpresidentContainer = React.lazy(() => import('../component/Administration/GbpresidentContainer'));
// const TrimurtiContainer = React.lazy(() => import('../component/Administration/TrimurtiContainer'));
// const TenderComponent = React.lazy(() => import('../component/Tenders/TenderComponent'));

export default class HomepageContainer extends Component {
  render() {
    return (
      <div>
        <PanduNav history={this.props.history} />

        <Switch>
          <Route exact path="/" component={HomePagePandu} />
          <Route
            exact
            path="/administrations/:link"
            component={AdministrationContainer}
          />
          <Route exact path="/aboutus/:link" component={PanduAbout} />
          <Route
            exact
            path="/notifications/:link"
            component={NotificationComponent}
          />
          <Route
            exact
            path="/notifications/:link/:year"
            component={NotificationComponent}
          />

          <Route
            exact
            path="/programs/:link/:year"
            component={ProgramsComponent}
          />

<Route
            exact
            path="/grievances"
            component={GrievanceComponent}
          />



          <Route exact path="/programs/:link" component={ProgramsComponent} />

          <Route exact path="/events" component={Events} />
          <Route exact path="/singleevent" component={SingleEvent} />
          <Route exact path="/departments" component={StreamsContainer} />
          <Route
            exact
            path="/student-wings"
            component={StudentWingsContainer}
          />
          <Route exact path="/students/:link" component={PanduStudents} />
          <Route exact path="/facility/:link" component={PanduFacility} />
          <Route exact path="/ncc/:link" component={NccFacility} />
          <Route exact path="/nss/:link" component={NssFacility} />
          <Route exact path="/research/:link" component={ResearchContainer} />
          <Route exact path="/research" component={ResearchContainer} />
          <Route exact path="/research-projects" component={ResearchProjects} />
          <Route exact path="/publications" component={PublicationsContainer} />
          <Route exact path="/feedbacks" render={() => <SingleNotificationAlikeComponent title="Feedbacks" code="IQAC_FEEDBACK_FORM" />} />


          <Route exact path="/aishe-data" render={() => <>
            <SingleNotificationAlikeComponent code="IQAC_AISHE_DATA" title="AISHE Data" noMinHeight={true} showBanner={true} />
          <SingleNotificationAlikeComponent code="IQAC_AISHE_DATA_C" title="AISHE Certificates" noMinHeight={true} showBanner={false} />
          </>} />
          <Route
            exact
            path="/wallmagazines"
            component={WallmagazineContainer}
          />
          <Route exact path="/newsletter" component={NewsletterContainer} />
          <Route exact path="/iqac/:link" component={IqacComponent} />
          <Route exact path="/nirf" component={NIRF} />
          <Route
            exact
            path="/visitors-note"
            component={VisitorsNoteContainer}
          />

          <Route exact path="/rti/:link" component={RtiContainer} />
          <Route exact path="/importantfile" component={Importantfiles} />
          <Route exact path="/emblem" component={EmblemContainers} />
          <Route exact path="/syllabus-course" component={SyllabusContainer} />
          <Route exact path="/organo" component={OrganogramContainer} />
          <Route exact path="/kkhsou" component={KkhsouContainer} />
          <Route exact path="/ignou" component={IgnouContainer} />
          <Route exact path="/idol" component={IdolContainer} />
          <Route exact path="/nptel" component={NptelChapter} />
          <Route exact path="/ncc" component={NccContainer} />

          <Route exact path="/course" component={CourseContainer} />
          <Route exact path="/admission-procedure" component={AdmissionProcedureContainer} />
          <Route exact path="/examination" component={ExaminationContainer} />
          <Route
            exact
            path="/rulesandregulations"
            component={RulesAndRegulationsContainer}
          />

          <Route
            exact
            path="/academiccalendar"
            component={AcademicCalendarContainer}
          />

          <Route
            exact
            path="/evaluationsystem"
            component={EvaluationSystemContainer}
          />

          <Route exact path="/alumni/:link" component={AlumniComponent} />
          <Route exact path="/contactus" component={ContactUsContainer} />
          <Route exact path="/committees" component={CommitteesContainer} />

          <Route
            exact
            path="/department/:dept_code/faculty/single"
            render={(props) => (
              <FacultySingleLanding
                {...props}
                dept_code={props.match.params.dept_code}
                // dept_name={this.state.data.dept_name}
              />
            )}
          />

          <Route
            exact
            path="/department/:dept_code/faculty/quarterlyreport"
            render={(props) => (
              <FacultyQuarterlyReportLanding
                {...props}
                dept_code={props.match.params.dept_code}
                // dept_name={this.state.data.dept_name}
              />
            )}
          />
          <Route exact path="/gallery/:link" component={GalleryComponent} />

          {/* <Route exact path="/notifications" component={FullNews} /> 
          <Route exact path="/officestaff" component={OfficeStaff} />
          <Route exact path="/supportstaff" component={SupportingStaff} />
          <Route exact path="/librarystaff" component={LibraryStaff} />
          <Route exact path="/vision" component={VisionContainer} />
          <Route exact path="/mission" component={MissionContainer} />
          <Route exact path="/departments" component={DepartmentsContainer} />
          <Route exact path="/tenders" component={FullTender} />
          <Route exact path="/rti" component={RtiContainer} />

          <Route exact path="/principal" component={PrincipalContainer} />
          <Route exact path="/gbpresident" component={GbpresidentContainer} />
          <Route exact path="/secretary" component={GbContainer} />
          <Route exact path="/viceprincipal" component={ViceprincipalContainer} />
          <Route exact path="/hods" component={HodContainer} />
          <Route exact path="/coursecordinator" component={CourseCordinatorContainer} />
          <Route exact path="/governingbody" component={GoverningBody} />
          <Route exact path="/managementbody" component={ManagementBody} />
          <Route exact path="/generalrule" component={GeneralRule} />
          <Route exact path="/trimurti" component={TrimurtiContainer} />

          <Route exact path="/library" component={LibraryContainer} />


          <Route exact path="/infrastructure/:link" component={InfrastructureComponent} />
          <Route exact path="/library/:link" component={LibraryComponent} />


          <Route exact path="/academic/:link" component={AcademicComponent} />
          <Route exact path="/others/:link" component={OthersComponent} />
          <Route exact path="/tenders/:link" component={TenderComponent} />
          <Route exact path="/publication/:link" component={PublicationComponent} />
          <Route exact path="/facilities/:link" component={FacilitiesComponent} />
          <Route exact path="/achievements/:link" component={AchievementsComponent} />
          <Route exact path="/focus/:link" component={FocusComponent} />
          <Route exact path="/digital/:link" component={OnlineClassesComponent} />
          <Route exact path="/innovatives/:link" component={InnovativePracticesComponent} />
          <Route exact path="/extensions/:link" component={ExtensionComponent} />
          <Route exact path="/supports/:link" component={SupportComponent} />
          
          */}
        </Switch>

        <PanduFooter />
      </div>
    );
  }
}
