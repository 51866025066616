import { Container, Grid } from "@mui/material";
import { Divider } from "antd";
import React, { Component } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { PostData } from "../api/service";
import { Typography } from "@material-ui/core";

const handleDragStart = (e) => e.preventDefault();

export default class Hightlights extends Component {
  state = {
    loadedData: [],
    isLoaded: false,
  };

  componentDidMount() {
    PostData(`/getphotosforwebsitewithcaption`, {
      type: "HIGHLIGHTS",
    }).then((resp) => {
      this.setState({
        loadedData: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoaded && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                {this.state.loadedData.filter((el) => el.type == "HIGHLIGHTS")
                  .length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "#ABB2B9",
                      }}
                    >
                      STUDENT PROGRESSION & JOB PLACEMENT
                    </Divider>
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == "HIGHLIGHTS")
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                {this.state.loadedData.filter(
                  (el) => el.type == "RESEARCH_HIGHLIGHTS"
                ).length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "#ABB2B9",
                      }}
                    >
                      RESEARCH HIGHLIGHTS
                    </Divider>
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == "RESEARCH_HIGHLIGHTS")
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                {this.state.loadedData.filter(
                  (el) => el.type == "NCC_HIGHLIGHTS"
                ).length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "#ABB2B9",
                      }}
                    >
                      NCC HIGHLIGHTS
                    </Divider>
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == "NCC_HIGHLIGHTS")
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                {this.state.loadedData.filter(
                  (el) => el.type == "NSS_HIGHLIGHTS"
                ).length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "#ABB2B9",
                      }}
                    >
                      NSS HIGHLIGHTS
                    </Divider>
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == "NSS_HIGHLIGHTS")
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid>

              {/* <Grid item xs={12} sm={12} md={3} lg={3}>
                {this.state.loadedData.filter(
                  (el) => el.type == "FACILITY_HIGHLIGHTS"
                ).length > 0 && (
                  <>
                    <Divider
                      orientation="center"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "#ABB2B9",
                      }}
                    >
                      FACILITIES
                    </Divider>
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == "FACILITY_HIGHLIGHTS")
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid> */}


              
            </Grid>
          </>
        )}
      </div>
    );
  }
}
