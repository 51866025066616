import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import DepertmentContainer from "./DepertmentContainer";
import history from "../history";
import { GetData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import queryString from "query-string";
const list = [
  {
    title: "Arts",
    link: "ARTS",
    bg: "./images/streams/arts.jpg",
  },
  {
    title: "Science",
    link: "SCIENCE",
    bg: "./images/streams/science.jpg",
  },
];

class StreamsContainer extends Component {
  state = {
    stream: "ARTS",
    departments: [],
    f_dept: [],
    isLoaded: false,
  };

  getDepartment() {
    GetData(`/getdepartments`).then((resp) => {
      let data = [...resp];
      let stream = "ARTS"

      let search = this.props.history.location.search;
      if (search) {
        let searchData = queryString.parse(search);

        if (searchData) {
          data = resp.filter((el) => el.stream == searchData.stream)
          stream = searchData.stream;
        }
      }

      this.setState({
        departments: resp,
        f_dept: [...data],
        stream,
        isLoaded: true,
      });
    });
  }

  componentDidMount() {
    this.getDepartment();

    
  }

  componentDidUpdate(prevState, prevProps){
    let searchnew = queryString.parse(this.props.history.location.search);
      if (searchnew) {
       
        if(this.state.stream != searchnew.stream){
          
          this.setNewData(searchnew.stream)
        }
      }
  }

  setNewData(val) {
    this.setState(
      {
        isLoaded: false,
        stream: val,
      },
      () => {
        let data = this.state.departments.filter(
          (el) => el.stream == this.state.stream
        );
        this.setState({
          f_dept: data,
          isLoaded: true,
        });
      }
    );
  }

  //   componentDidUpdate(prevProps, prevState) {
  //    if(prevProps !=prevState){
  //     this.setState({
  //         isLoaded:false,
  //     },()=>{
  //        this.getDepartment();
  //     })
  //    }
  //   }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Departments" />
        <Container className="mb-4">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "1em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                          background: `url("${el.bg}")`,
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "1em 0",
                        }}
                        key={index}
                        // onClick={() => this.props.history.push(el.link)}
                        onClick={() => this.setNewData(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.5em",
                            fontWeight: "500",
                            padding: "0",
                            background: "#FFFC",
                            width: "100%",
                            padding: ".5em",
                            textAlign: "center",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            {this.state.isLoaded && (
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <DepertmentContainer
                  type={this.state.stream}
                  departments={this.state.f_dept}
                  history={history}
                />
              </Grid>
            )}
          </Grid>
        </Container>
        {/* {this.state.isLoaded && <DepertmentContainer type={this.state.stream} departments={this.state.f_dept} history={history}/>} */}
      </div>
    );
  }
}

export default StreamsContainer;
