import React, { Component } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  Button,
} from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import BreadCumb from "../Department/BreadCumb";
import { PostData } from "../../api/service";
import { Container, Typography } from "@material-ui/core";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]

export default class PresidentContainer extends Component {
  state = {
    facultyData: [],
    isLoaded: false,
    dept_code: "OFF",
  };

  componentDidMount() {
    let d = {
      dept_code: "OFF",
      type: "PRESIDENT",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      //console.log(resp)
      this.setState({
        ...resp[0],
        facultyData: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <BreadCrumbOwn title="President's Message" />

        <Container className="mb-5">
          <br />

          {this.state.isLoaded &&
            this.state.facultyData.map((el, index) => {
              return (
                <div>
                  {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}
                  <div>
                        <div style={{ textAlign: "left" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "350px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div>

                        {/* <h5
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}
                        </h5> */}
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        {/* <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a>
                        </h6> */}
                      </div>
                  <div>
                    <div>
                      <Typography
                        variant="h5"
                        style={{ margin: "1em 0", fontWeight: 700 }}
                        gutterBottom
                      >
                        FROM THE PRESIDENT’S DESK
                      </Typography>
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >
                       It is with great pride and privilege that I welcome you to the official website of Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool. As an institution dedicated to academic excellence, we strive to provide a nurturing environment that fosters the holistic development of our students.
                      </Typography>

                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      > am pleased to inform you that Sribhumi is one of the first five districts in Assam to be blessed by the Government of Assam with the establishment of a Government Model Degree College (Science). This institution is aimed at providing quality science education to the socio-economically disadvantaged rural communities of South Sribhumi. Since its inception in 2017, the Mahavidyalaya has been offering science education at both the Higher Secondary and Undergraduate (UG) levels. In line with its commitment to comprehensive education, the Mahavidyalaya proudly introduced an Arts stream at the UG level from the Academic Session 2023-2024.
</Typography>
                       <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Government of Assam’s vision in establishing this higher education institution is deeply inspired by the ideals of the visionary statesman, social thinker, and integral humanist Pandit Deendayal Upadhyaya Ji, whose vision for socio-economic upliftment of rural communities continues to guide our mission. Access to higher education remains a significant challenge for underprivileged communities, especially in developing countries. Acknowledging this, the Government of India has taken it upon itself to deliver quality education to the rural population at their doorstep. In this regard, the establishment of model degree colleges like ours is a crucial step in meeting the educational needs of rural Assam.</Typography>
                       <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Mahavidyalaya began its academic journey in the 2017-2018 session, offering Higher Secondary Science Stream and B.Sc. (both Honours and Pass) courses. The Mahavidyalaya is spread across a vast, lush campus of 30 bighas, featuring well-designed academic buildings and a sprawling five-bigha playground. Located in Eraligool, 26 km from Sribhumi town, our campus is easily accessible by various modes of transportation.</Typography>
                       <br />

<Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Mahavidyalaya also provides residential quarters for teaching and non-teaching staff, as well as a Girls' Hostel, ensuring a comfortable living environment for our community. Dr. Jayasree Chakrabarty, an esteemed academician with a distinguished tenure at Cotton College, Guwahati (now Cotton University), has been appointed as the Founding Principal of the Mahavidyalaya. Her dynamic leadership continues to shape the institution’s growth and development.</Typography>
                       <br />

<Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >Beyond academics, the Mahavidyalaya is committed to various social and academic initiatives aimed at enhancing the welfare of the surrounding communities. We sincerely invite the support of local educators, parents, students, and the general public to join hands in fulfilling our mission of delivering high-quality education in Sribhumi District. </Typography>
                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >I warmly welcome all stakeholders to be part of the PDUAM, Eraligool family. Together, let us work towards a brighter future by upholding the values of education, progress, and innovation. As the Mahavidyalaya prepares for its upcoming NAAC assessment and accreditation, I request the active participation of all stakeholders in this important process.</Typography>
                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >I extend my best wishes to all our students, faculty, and staff as we embark on this journey of collective achievement.</Typography>
                      <br />

                      <div>
                        {/* <div style={{ textAlign: "right" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "250px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div> */}

                        <h4
                          style={{
                            fontSize: "20px",
                            textAlign: "right",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}
                        </h4>
                        <h6 style={{ fontSize: "14px", textAlign: "right", color: "black" }}>
                        <div dangerouslySetInnerHTML={{ __html: el.designation }}></div>
                   
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        {/* <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a>
                        </h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Container>
      </div>
    );
  }
}
