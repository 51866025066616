import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";

export default class IgnouContainer extends Component {
    render() {
        return (
            <div>

                <BreadCrumbOwn title="IGNOU" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ backgroundColor: "#F2F4F4" }}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Programms Under Indira Gandhi National Open University(IGNOU)
                                </Typography>


                                
                                <Typography variant="body1">

                                    <ul>
                                        <li>B.Sc. General</li>
                                        <li>B.Sc. in Geography</li>
                                        <li>B.Sc. in Geology</li>
                                        <li>Tourism Management</li>
                                        <li>Disaster Management</li>
                                    </ul>

                                </Typography>

                               


                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    COORDINATOR
                                </Typography>
                                {/* <img
                                    src="/images/kkhsou-co.jpeg"
                                    style={{ width: 200 }}
                                />
                                <br />
                                <br /> */}
                                <Typography variant="body1">
                                    <strong>Dr. Amarjyoti Dutta</strong>
                                    <br />
                                    Associate Professor
                                    <br />
                                    Mathematics Department
                                    <br />

                                    Phone:{" "}
                                    <a href={`tel:9864305333`}>
                                    9864305333
                                    </a>{" "}
                                    <br />
                                    Email: <a href={`mailto:amar@pragjyotishcollege.ac.in`}>amar@pragjyotishcollege.ac.in</a>

                                </Typography>

                            </Grid>

                        </Grid>

                    </div>
                </Container>
            </div>
        );
    }
}
