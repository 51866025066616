import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";

export default class KkhsouContainer extends Component {
    render() {
        return (
            <div>

                <BreadCrumbOwn title="KKHSOU" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ backgroundColor: "#F2F4F4" }}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Programms Under Krishna Kanta Handiqui State Open University (KKHSOU)
                                </Typography>


                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Post Graduate Programs
                                </Typography>
                                <Typography variant="body1">

                                    <ul>
                                        <li>M.A. (Assamese)</li>
                                        <li>M.A. (English)</li>
                                        <li>M.A. (Sociology)</li>
                                    </ul>

                                </Typography>

                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Under Graduate Programs
                                </Typography>
                                <Typography variant="body1">

                                    <ul>
                                        <li>B.A.</li>
                                        <li>M.Com.</li>
                                        <li>B.B.A</li>
                                        <li>B.C.A</li>
                                    </ul>

                                </Typography>


                                <br />

                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Documents
                                </Typography>

                                <ul>
                                    <li><a target="_blank" href={`/pdf/kkhsou/1502 PROGRAMME LIST.pdf`}>1502 PROGRAMME LIST</a></li>
                                    <li><a target="_blank" href={`/pdf/kkhsou/1502 COURSE ALLOTMENT.pdf`}>1502 COURSE ALLOTMENT</a></li>
                                    <li><a target="_blank" href={`/pdf/kkhsou/1502 COUNSELLOR LIST (UG, DIP).pdf`}>1502 COUNSELLOR LIST (UG, DIP)</a></li>
                                    <li><a target="_blank" href={`/pdf/kkhsou/1502 COUNSELLOR LIST (PG).pdf`}>1502 COUNSELLOR LIST (PG)</a></li>
                                </ul>


                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    COORDINATOR
                                </Typography>
                                {/* <img
                                    src="/images/kkhsou-co.jpeg"
                                    style={{ width: 200 }}
                                />
                                <br />
                                <br /> */}
                                <Typography variant="body1">
                                    <strong>Dr. Manjit Kr. Mazumdar</strong>
                                    <br />
                                    Associate Professor
                                    <br />
                                    Geology Department
                                    <br />

                                    Phone:{" "}
                                    <a href={`tel:9706433118`}>
                                        9706433118
                                    </a>{" "}
                                    <br />

                                </Typography>

                            </Grid>

                        </Grid>

                    </div>
                </Container>
            </div>
        );
    }
}
