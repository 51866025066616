import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import HomePage from './HomePage';
import BreadCumb from './BreadCumb';
import FacultyPage from './FacultyPage';
import Syllabus from './Syllabus';
import { PostData } from '../../api/service';
import FacilitiesPage from './FacilitiesPage';
import DepartmentNavigation from './DepartmentNavigation';
import VisionPage from './VisionPage';
import GlancePage from './GlancePage';
import CoursePage from './CoursePage';
import ContactPage from './ContactPage';
import OthersPage from './ResearchPage';
import Journal from './Journal';
import DepartmentEvents from './DepartmentEvents';
import Achievements from './Achievements';
import Alumni from './Alumni';
import FooterPage from '../FooterPage';
import Activity from './Activity';
import OnlineClasses from './OnlineClasses';
import FacultySingleLanding from '../FacultySingle/FacultySingleLanding';
import PanduFooter from '../HeaderComponent/PanduFooter';
import PanduNav from '../HeaderComponent/PanduNav';
import ResearchPage from './ResearchPage';
import GalleryPage from './Gallery';
import ProfilePage from './ProfilePage';
import CoPo from './CoPo';
import BreadCrumbOwn from '../Essentials/BreadCrumbOwn';
import { Container } from '@material-ui/core';
import DepartmentWallMagazine from './DepartmentalWallMagainze';
import DepartmentSingleEvent from './DepartmentSingleEvent';
import DepartmentNotices from './DepartmentNotices';
import BreadCrumbOwnDept from '../Essentials/BreadCrumbOwnDept';
import OfficeStaff from './OfficeStaff';

export default class DepartmentHome extends Component {
    state = {
        dept_code: this.props.match.params.dept_code,
        isLoaded: false,
        data: [],
        last: '',
        dept_name: ""
    }

    componentDidMount() {
        let dd = this.props.location.pathname;
        let ddd = dd.split('/');
        let last = ddd[ddd.length - 1];

        let d = {
            dept_code: this.state.dept_code
        }
        PostData('/getdeptdata', d)
            .then((resp) => {
                console.log(resp);

                this.setState({
                    isLoaded: true,
                    data: resp,
                    last: last,
                    dept_name: resp.dept_name
                })
            })
    }

    render() {
        return (
            <div>
                <PanduNav
                    history={this.props.history}
                    data={this.state.data}
                />

                
                <BreadCrumbOwnDept title={this.state.dept_name} data={this.state.data} navList={<DepartmentNavigation
                    data={this.state.data}
                    history={this.props.history}
                    dept_code={this.state.data.dept_code}
                />} />

                
                <Container className='mb-5'>

                    {this.state.isLoaded ?
                        <Switch>
                            <Route exact path="/department/:dept_code" render={(props) => <HomePage
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/vision" render={(props) => <VisionPage
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/profile" render={(props) => <ProfilePage
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/glance" render={(props) => <GlancePage
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/course" render={(props) => <CoursePage
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/contact" render={(props) => <ContactPage
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/research" render={(props) => <ResearchPage
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/onlineclasses" render={(props) => <OnlineClasses
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/faculty" render={(props) => <FacultyPage
                                {...props}
                                data={this.state.data} />} />
                                <Route exact path="/department/:dept_code/staff" render={(props) => <OfficeStaff
                                {...props}
                                data={this.state.data} />} />


                            {/* <Route exact path="/department/:dept_code/faculty/single" render={(props) => <FacultySingleLanding
                                {...props}
                                dept_code={this.state.dept_code}
                                dept_name={this.state.data.dept_name}
                                 />} /> */}
                            <Route exact path="/department/:dept_code/syllabus" render={(props) => <Syllabus
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/profile" render={(props) => <HomePage
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/journal" render={(props) => <Journal
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/co_po" render={(props) => <CoPo
                                {...props}
                                data={this.state.data} />} />
                            <Route exact path="/department/:dept_code/facilities" render={(props) => <FacilitiesPage
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/events" render={(props) => <DepartmentEvents
                                {...props}
                                data={this.state.data} />} />
                                <Route exact path="/department/:dept_code/notices" render={(props) => <DepartmentNotices
                                {...props}
                                data={this.state.data} />} />

<Route exact path="/department/:dept_code/singleevent" render={(props) => <DepartmentSingleEvent
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/wall-magazine" render={(props) => <DepartmentWallMagazine
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/achievements" render={(props) => <Achievements
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/alumni" render={(props) => <Alumni
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/activity" render={(props) => <Activity
                                {...props}
                                data={this.state.data} />} />

                            <Route exact path="/department/:dept_code/gallery" render={(props) => <GalleryPage
                                {...props}
                                data={this.state.data} />} />
                        </Switch>
                        : null}


                </Container>
                {/* <FooterPage
                    {...this.props}
                /> */}
                <PanduFooter />
            </div>
        )
    }
}
