import React, { Component } from "react";
import BreadCumb from "../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { PostData } from "../api/service";
import NotificationDescription from "../component/Notifications/NotificationDescription";

export default class AcademicCalendarContainer extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Academic Calendars" />
        <NotificationDescription
          code="ACADEMIC_CALENDAR"
          title="Academic Calendars"
        />
      </div>
    );
  }
}
