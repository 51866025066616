import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";

export default class ContactUsContainer extends Component {
  render() {
    return (
      <div>

        <BreadCrumbOwn title="Contact Us" />
        <Container style={{ minHeight: '600px' }}>
          <br></br>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ul>
                  <li style={{ paddingBottom: "", listStyle: 'none', fontSize: '1.2em', marginLeft: "-40px" }}>
                    <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                      Postal Address
                    </Typography>
                    <Typography variant="h6">
                      Pandit Deendayal Upadhyaya Mahavidyalaya
                      <br />
                      Eraligool,Karimganj,Assam (India)
                      <br />
                      Pin : 788723
                      <br />
                      Email:{" "}
                      <a href={`mailto:principal.eraligool@gmail.com`}>
                      principal.eraligool@gmail.com
                      </a>
                      <br />
                      Webmail:{" "}
                      <a href={`mailto:principal.eraligool@gmail.com`}>
                      principal.eraligool@gmail.com
                      </a>
                      <br />
                      Website:{" "}
                      <a href={`https://pduameraligool.ac.in/`}>
                        https://pduameraligool.ac.in/
                      </a>{" "}
                      <br />
                      
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.836301504616!2d92.35645947536463!3d24.663762378058244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3751d9b5c3c3bbb5%3A0x7493c35e86d1543d!2sPandit%20Deendayal%20Upadhyaya%20Adarsha%20Mahavidyalaya%20Eraligool!5e0!3m2!1sen!2sin!4v1710824043567!5m2!1sen!2sin"
                  width="600"
                  height="400"
                  style={{ border: 0, maxWidth: '100%' }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>

              </Grid>
            </Grid>

          </div>
        </Container>
      </div>
    );
  }
}
