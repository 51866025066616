import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Button, Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import RecentNews from "./RecentNews";
import HomePageNews from "./News/HomePageNews";

export default function HomePageTopDiv() {
  const media = useMediaQuery("(max-width: 768px)");
  return (
    <div>
      {" "}
      {!media && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/admission-procedure">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                    // color: this.state.isHovered ? "#1D8EFA" : "blue"
                  }}
                  // onMouseEnter={this.handleMouseOver}
                  // onMouseLeave={this.handleMouseLeave}
                >
                  ADMISSION
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/committees">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  CELL/COMMITTEE
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/rti/home">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  RTI CELL
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <a href="https://viksitbharatsankalp.gov.in/">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  VIKASHIT BHARAT
                </Button>
              </a>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/aishe-data">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  AISHE
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <a href="https://voters.eci.gov.in/">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  NEW VOTERS' LINK
                </Button>
              </a>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/students/downloads">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  FORMS & DOWNLOADS
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/feedbacks">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  FEEDBACK
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "2vh" }}>
              <Link to="/programs/reports">
                <Button
                  style={{
                    width: "90%",
                    height: "60px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  PROGRAM REPORT
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ height: "40",  }}>
              <RecentNews />
            </div>
            <div style={{ padding: 10, marginTop: 10, }}>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, fontSize: 16 }}
                gutterBottom
              >
                About The College
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify", fontSize: 14 }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Mahavidyalaya was established at Eraligool in the district of Karimganj on 1st September 2017 by the Government of Assam with the financial assistance from the Rashtriya Uchchatar Shiksha Abhiyan (RUSA). It is the first and only Government Model Degree College in the Barak Valley dedicated to Science education. The institution is named after Pandit Deendayal Upadhyaya, a great thinker, philosopher and renowned sociologist of the country. The objective behind the government initiative to establish this Mahavidyalaya is to provide quality higher education along with a distinct focus on Science education which is affordable and accessible to all sections of the society. The Mahavidyalaya, which came into existence through continuous efforts of the Department of Higher Education and the RUSA, had finally been able to open the gate of higher education to the first batch of students from the academic session 2017-2018....<Link to="/aboutus/about">Read More</Link>
                {/* <VideoHomePage viewTitle={false} /> */}
              </Typography>
            </div>
            <div></div>
            <div>
              <Divider
                orientation="left"
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  borderColor: "#ABB2B9",
                  paddingLeft: 50,
                }}
              >
                President's Message
              </Divider>
              <div
                style={{ width: "100%", position: "relative",  }}
              >
                <img
                  src="/images/pres-112.png"
                  className="img-wrap-image"
                />

                <div className="p-warp-image">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ textAlign: "justify", padding: 10, fontSize: 14 }}
                  >
                     It is with great pride and privilege that I welcome you to the official website of Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool. As an institution dedicated to academic excellence.....<Link to="/administrations/president"><a>Read more</a></Link>
                  </Typography>

                 
                </div>
              </div>
              <Divider
                orientation="left"
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  borderColor: "#ABB2B9",
                  paddingLeft: 50,
                }}
              >
                Principal's Message
              </Divider>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  minHeight: "10vh",
                  
                }}
              >
                <img
                  src="/images/principal_panditdeen.jpg"
                  className="img-wrap-image"
                />

                <div className="p-warp-image">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ textAlign: "justify", padding: 10, fontSize: 14 }}
                  >
                   I am pleased to announce the commencement of a new academic session 2023-2024 at Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool, Karimganj. Through collaboration with various stakeholders of the Mahavidyalaya........<Link to="/administrations/principal"><a>Read more</a></Link>
                  </Typography>

                 
                </div>
              </div>
            </div>

            <br />
            <br />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ marginTop: 10,paddingTop:40, backgroundColor:'#D6EAF8' }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Student Notifications
                  </Divider>
                  <div style={{paddingLeft: 20, paddingRight: 10, paddingBottom: 10}}>
                  <HomePageNews
                    type="COLLEGE_ACADEMIC_NOTICE"
                    link="/notifications/academic"
                  /></div>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ paddingTop: 40 }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    News & Events
                  </Divider>
                  <HomePageNews
                    type="COLLEGE_EVENT"
                    link="/events"
                  />
                </div>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div style={{ paddingTop: 40, marginBottom: 20 ,backgroundColor:'#D4EFDF'}}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Administrative Notification
                  </Divider>
                  <div style={{paddingLeft: 20, paddingRight: 10, paddingBottom: 10}}>
                  <HomePageNews
                    type="COLLEGE_ADMIN_NOTICE"
                    link="/students/administrative"
                  />
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ paddingTop: 40 }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Downloads
                  </Divider>
                  <HomePageNews
                    type="COLLEGE_DOWNLOADS"
                    link="/students/downloads"
                  />
                </div>
              </Grid> */}
            </Grid>

            {/* <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Button
              style={{
                width: "80%",
                height: "60px",
                border: "solid",
                borderColor: "#443205",
                fontSize: 16,
                fontWeight: 700,
                color: "white",
                backgroundColor: "#443205",
                boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
              }}
            >
              New Voters' Link 1
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Button
              style={{
                width: "80%",
                height: "60px",
                border: "solid",
                borderColor: "#443205",
                fontSize: 16,
                fontWeight: 700,
                color: "white",
                backgroundColor: "#443205",
                boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
              }}
            >
              New Voters' Link 2
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/rti/home">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                RTI Cell
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/committees">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                CELLS/ COMMITTEE
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/iqac/feedback">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                <a></a>FEEDBACK
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/gallery/photo">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                GALLERY
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/notifications/achievements">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                ACHIEVEMENTS
              </Button>
            </Link>
          </div> */}
          </Grid>
        </Grid>
      )}
      {media && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div style={{ height: "20" }}>
              <RecentNews />
            </div>
            <div style={{ padding: 10, marginTop: 10 }}>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, fontSize: 18 }}
                gutterBottom
              >
                About The College
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify", fontSize: 14 }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
                Mahavidyalaya was established at Eraligool in the district of
                Karimganj on 1st September 2017 by the Government of Assam with
                the financial assistance from the Rashtriya Uchchatar Shiksha
                Abhiyan (RUSA)....... <Link to="/aboutus/about">Read More</Link>
                {/* <VideoHomePage viewTitle={false} /> */}
              </Typography>
            </div>
            <div></div>
            <div>
              <Divider
                orientation="left"
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  borderColor: "#ABB2B9",
                }}
              >
                President's Message
              </Divider>
              <div style={{ width: "100%", position: "relative" }}>
                <img
                  src="/images/pres-112.png"
                  className="img-wrap-image"
                />

                <div className="p-warp-image">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ textAlign: "justify", padding: 10, fontSize: 14 }}
                  >
                    It is with great pride and privilege that I welcome you to the official website of Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool. As an institution dedicated to academic excellence.....
                  </Typography>

                  <div style={{ textAlign: "right" }}>
                    <Link to="/administrations/president">
                      <Button type="primary">Read More</Button>
                    </Link>
                  </div>
                </div>
              </div>
              <Divider
                orientation="left"
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  borderColor: "#ABB2B9",
                }}
              >
                Principal's Message
              </Divider>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  minHeight: "10vh",
                }}
              >
                <img
                  src="/images/principal_panditdeen.jpg"
                  className="img-wrap-image"
                />

                <div className="p-warp-image">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ textAlign: "justify", padding: 10, fontSize: 14 }}
                  >
                    I am pleased to announce the commencement of a new academic session 2023-2024 at Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool, Karimganj. Through collaboration with various stakeholders of the Mahavidyalaya.........
                  </Typography>

                  <div style={{ textAlign: "right" }}>
                    <Link to="/administrations/principal">
                      <Button type="primary">Read More</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/admission-procedure">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                    // color: this.state.isHovered ? "#1D8EFA" : "blue"
                  }}
                  // onMouseEnter={this.handleMouseOver}
                  // onMouseLeave={this.handleMouseLeave}
                >
                  ADMISSION
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 9,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  CELL/COMMITTEE
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/rti/home">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  RTI CELL
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <a href="https://viksitbharatsankalp.gov.in/">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 10,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  VIKASHIT BHARAT
                </Button>
              </a>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/aishe-data">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  AISHE
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <a href="https://voters.eci.gov.in/">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 9,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  NEW VOTERS' LINK
                </Button>
              </a>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/students/downloads">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 8,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  FORMS & DOWNLOADS
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/feedbacks">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  FEEDBACK
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "1vh" }}>
              <Link to="/programs/reports">
                <Button
                  style={{
                    width: "90%",
                    height: "40px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 10,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  PROGRAM REPORTS
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={8} sm={8} md={2} lg={2}><div style={{ paddingTop: 20, paddingRight: 10 }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Student Notifications
                  </Divider>
                  <HomePageNews
                    type="COLLEGE_ACADEMIC_NOTICE"
                    link="/notifications/academic"
                  />
                </div></Grid>
                {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                <div style={{ paddingTop: 40 }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    News & Events
                  </Divider>
                  <HomePageNews
                    type="COLLEGE_EVENT"
                    link="/events"
                  />
                </div>

                </Grid> */}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                <div style={{ paddingTop: 40 , paddingBottom:50,}}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Administrative Notification
                  </Divider>
                  <div style={{paddingLeft: 20}}>
                  <HomePageNews
                    type="COLLEGE_ADMIN_NOTICE"
                    link="/students/administrative"
                  /></div>
                </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ paddingTop: 40 }}>
                  <Divider
                    orientation="left"
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      borderColor: "#ABB2B9",
                      marginTop: "-20px",
                    }}
                  >
                    Downloads
                  </Divider>
                  <HomePageNews
                    type="COLLEGE_DOWNLOADS"
                    link="/students/downloads"
                  />
                </div>
                </Grid> */}
          <Grid item xs={8} sm={8} md={6} lg={6}>
            

            {/* <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Button
              style={{
                width: "80%",
                height: "60px",
                border: "solid",
                borderColor: "#443205",
                fontSize: 16,
                fontWeight: 700,
                color: "white",
                backgroundColor: "#443205",
                boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
              }}
            >
              New Voters' Link 1
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Button
              style={{
                width: "80%",
                height: "60px",
                border: "solid",
                borderColor: "#443205",
                fontSize: 16,
                fontWeight: 700,
                color: "white",
                backgroundColor: "#443205",
                boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
              }}
            >
              New Voters' Link 2
            </Button>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/rti/home">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                RTI Cell
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/committees">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                CELLS/ COMMITTEE
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/iqac/feedback">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                <a></a>FEEDBACK
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/gallery/photo">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                GALLERY
              </Button>
            </Link>
          </div>
          <div style={{ textAlign: "center", paddingTop: "5vh" }}>
            <Link to="/notifications/achievements">
              <Button
                style={{
                  width: "80%",
                  height: "60px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 16,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                ACHIEVEMENTS
              </Button>
            </Link>
          </div> */}
          </Grid>

          {/* <Grid item xs={6} sm={6} md={2} lg={2}>
            
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Button
                style={{
                  width: "90%",
                  height: "50px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 14,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                New Voters' Link 1
              </Button>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Button
                style={{
                  width: "90%",
                  height: "50px",
                  border: "solid",
                  borderColor: "#443205",
                  fontSize: 14,
                  fontWeight: 700,
                  color: "white",
                  backgroundColor: "#443205",
                  boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                }}
              >
                New Voters' Link 2
              </Button>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Link to="/rti/home">
                <Button
                  style={{
                    width: "90%",
                    height: "50px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 14,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  RTI Cell
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Link to="/committees">
                <Button
                  style={{
                    width: "90%",
                    height: "50px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 14,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  CELLS/ COMMITTEE
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Link to="/iqac/feedback">
                <Button
                  style={{
                    width: "90%",
                    height: "50px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 14,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  <a></a>FEEDBACK
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Link to="/gallery/photo">
                <Button
                  style={{
                    width: "90%",
                    height: "50px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 14,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  GALLERY
                </Button>
              </Link>
            </div>
            <div style={{ textAlign: "center", paddingTop: "5vh" }}>
              <Link to="/notifications/achievements">
                <Button
                  style={{
                    width: "90%",
                    height: "50px",
                    border: "solid",
                    borderColor: "#443205",
                    fontSize: 14,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#443205",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
                  }}
                >
                  ACHIEVEMENTS
                </Button>
              </Link>

              <br />
              <br />
            </div>
          </Grid> */}
        </Grid>
      )}
    </div>
  );
}
